import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Landingstyle.css";
import ourappimg from "./Images/Group 149.png";
import ourappimgTamil from "./Images/Group149(1).png";
import mobileimg from "./Images/phone.png";
import play from "./Images/new-get-it-on-google-play-png-logo-20.png";
import ApiIcon from "@mui/icons-material/Api";
import learnicon from "./Images/Frame 51.png";
import Qaimage from "./Images/QuestionAndAnswer.png";
import ModelQues from "./Images/ModalQuestionPaper.png";
import ChoicebaseQuestion from "./Images/ChoiceBasedQuestion.png";
import cleardoubts from "./Images/ClearDoubts.png";
import languagesupport from "./Images/LanguageSupport.png";
import learnfromanywhere from "./Images/LearnFromAnywhere.png";
import devicesuppor from "./Images/DeviceSupports.png";
import appdemo from "./Images/Group 150.png";
import appdemoTamil from "./Images/Group 150(1).png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Icon } from "@iconify/react";
import footerplaystore from "./Images/google-play-badges.png";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { Button } from "@mui/material";
import Landingpagenavbar from "./LandingPageNavbar";
import TrailExpiry from "../common/modals/TrailExpiry/TrailExpiry";
import AdsComponent from "../GoogleAds/AdsComponent";
import { useTranslation } from "react-i18next";
function Landingpage() {
  const { t, i18n } = useTranslation();
  const block1Ref = useRef(null);
  const block2Ref = useRef(null);
  const block3Ref = useRef(null);
  const block4Ref = useRef(null);
  const ourAppImage = i18n.language === "en" ? ourappimg : ourappimgTamil;
  const appDemoImage = i18n.language === "en" ? appdemo : appdemoTamil;
  const handleButton1Click = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    block1Ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const demoVideoURL =
    "https://gillgall-app-public.s3.ap-south-1.amazonaws.com/appDemoVideo/Gillgall+30+SEC+OUT.mp4";
  const footerData = [
    {
      name: t("Terms & Conditions") + " |",
      link: "https://merchant.razorpay.com/policy/Mz9sDrBQvgLi8T/terms",
    },
    {
      name: t("Privacy Policy") + " |",
      link: "https://merchant.razorpay.com/policy/Mz9sDrBQvgLi8T/privacy",
    },
    {
      name: t("Cancellation & Refund Request") + " |",
      link: "https://merchant.razorpay.com/policy/Mz9sDrBQvgLi8T/refund",
    },
    {
      name: t("Contact Us"),
      link: "https://merchant.razorpay.com/policy/Mz9sDrBQvgLi8T/contact_us",
    },
  ];

  const handleButton2Click = () => {
    block2Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleButton3Click = () => {
    block3Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleButton4Click = () => {
    block4Ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleButton5Click = () => {
    navigate("/onboarding");
  };

  const navigate = useNavigate();
  const gotoplaystor = () => {
    window.open("https://play.google.com/");
  };

  const featuresData = [
    {
      title: t("Learn With Video Classes"),
      content: [
        t("Learn With Video Classes Content1"),
        t("Learn With Video Classes Content2"),
        t("Learn With Video Classes Content3"),
      ],
    },

    {
      title: t("Question and Answers"),
      content: [
        t("Question and Answers Content1"),
        t("Question and Answers Content2"),
        t("Question and Answers Content3"),
      ],
    },
    {
      title: t("Model Question Papers for all Subjects"),
      content: [
        t("Model Question Papers Content1"),
        t("Model Question Papers Content2"),
        t("Model Question Papers Content3"),
      ],
    },
    {
      title: t("Choice Based Questions"),
      content: [
        t("Choice Based Questions Content1"),
        t("Choice Based Questions Content2"),
        t("Choice Based Questions Content3"),
      ],
    },
    {
      title: <span className='single-line-tamil'>{t("Clear doubts anytime and anywhere")}</span>,
      content: [t("Clear doubts content1"), t("Clear doubts content2"), t("Clear doubts content3")],
    },

    {
      title: t("Language Support"),
      content: [
        t("Language Support Content1"),
        t("Language Support Content2"),
        t("Language Support Content3"),
      ],
    },
    {
      title: t("Learn from Anywhere"),
      content: [
        t("Learn from Anywhere Content1"),
        t("Learn from Anywhere Content2"),
        t("Learn from Anywhere Content3"),
      ],
    },
    {
      title: t("Device Support"),
      content: [
        t("Device Support Content1"),
        t("Device Support Content2"),
        t("Device Support Content3"),
      ],
    },
  ];
  var date = new Date();
  const getAnimationDuration = () => {
    const screenWidth = window.innerWidth;

    // Adjust these values based on your specific requirements
    if (screenWidth <= 600) {
      return 100; // Short duration for small screens
    } else {
      return 1000; // Longer duration for larger screens
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/home");
    }
  });
  return (
    <div ref={block1Ref}>
      <Landingpagenavbar
        onButton1Click={handleButton1Click}
        onButton2Click={handleButton2Click}
        onButton3Click={handleButton3Click}
        onButton4Click={handleButton4Click}
        onButton5Click={handleButton5Click}
      />
      <div className='gillmenu'>
        <div className='gillmenucontent'>
          <div className='wrapper'>
            <div className='divwith'>
              <div className='namecolor'>
                <h1 className='letterclr'>{t("GillGall")}</h1>
              </div>
              <Zoom>
                <div className='colorchange'>
                  <p className='lettercontent'>
                    <span className='gillgallcolor'>{t("Gillgall")} </span> {t("aboutGillgall")}
                    <br />
                    {t("aboutGillgall2")}
                  </p>
                </div>
              </Zoom>
              <Fade bottom big>
                <div className='namecolors'>
                  <img src={play} alt='play' onClick={gotoplaystor} className='colornames' />
                </div>
              </Fade>
            </div>
            <Fade right big duration={getAnimationDuration()}>
              <div className='seconddivwith'>
                <img src={mobileimg} alt='mobile' className='mobile' />
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <div className='ourappsecon'>
        <div className='ourapplicationdiv'>
          <img
            src={ourAppImage}
            className={i18n.language !== "en" ? "ourapp ourappimgTamil" : "ourapp"}
            alt='our app'
            ref={block2Ref}
          />
        </div>
      </div>
      {/* <------ Learn With Video Classes -------> */}
      <Fade bottom big>
        <div className='learnvideobg'>
          <div className='videoslearn'>
            <div className='divwidth'>
              <div className='learncontain'>
                <Fade left big>
                  <div className='leftsidecontent'>
                    <h1 className='learnclasses'>{featuresData[0].title}</h1>
                    {featuresData[0].content.map((item, index) => (
                      <div className='learncontent' key={index}>
                        <ApiIcon className='bulleticon' />
                        <p className='fontsize'>{item}</p>
                      </div>
                    ))}
                  </div>
                </Fade>
                <Fade right big duration={getAnimationDuration()}>
                  <div className='learnright'>
                    <img src={learnicon} className='learnrightimage' alt='learn icon' />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      {/* <------ Question and Answers  -------> */}
      {/* <Fade bottom big  > */}
      <div className='ouestionanswbgs'>
        <div className='videoslearns'>
          <div className='divwidths'>
            <div className='learncontains'>
              <Fade left big>
                <div className='learnrights'>
                  <img src={Qaimage} alt='qa' className='imagelearnrights' />
                </div>
              </Fade>
              <Fade right big duration={getAnimationDuration()}>
                <div className='leftsidecontents'>
                  <h1 className='learnclasseses'>{featuresData[1].title}</h1>
                  {featuresData[1].content.map((item, index) => (
                    <div key={index} className='learncontents'>
                      <ApiIcon className='bulleticons' />
                      <p className='fontsizess'>{item}</p>
                    </div>
                  ))}
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      {/* </Fade> */}
      {/* <-----------  Model Question Papers for all Subjects     --------------> */}
      <Fade bottom big>
        <div className='learnvideobge'>
          <div className='videoslearne'>
            <div className='divwidthe'>
              <div className='learncontaine'>
                <Fade left big>
                  <div className='leftsidecontente'>
                    <h1 className='learnclassese'>{featuresData[2].title}</h1>
                    {featuresData[2].content.map((item, index) => (
                      <div key={index} className='learncontente'>
                        <ApiIcon className='bulleticone' />
                        <p className='fontsizee'>{item}</p>
                      </div>
                    ))}
                  </div>
                </Fade>
                <Fade right big duration={getAnimationDuration()}>
                  <div className='learnrighte'>
                    <img src={ModelQues} className='learnrightimagee' alt='model' />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </Fade>

      {/* <----------- Choice Based Questions    --------------> */}

      <Fade bottom big>
        <div className='ouestionanswbgsa'>
          <div className='videoslearnsa'>
            <div className='divwidthsa'>
              <div className='learncontainsa'>
                <Fade left big>
                  <div className='learnrightsa'>
                    <img src={ChoicebaseQuestion} alt='choice' className='imagelearnrightsa' />
                  </div>
                </Fade>
                <Fade right big duration={getAnimationDuration()}>
                  <div className='leftsidecontentsa'>
                    <h1 className='learnclassesesa'>{featuresData[3].title}</h1>
                    {featuresData[3].content.map((item, index) => (
                      <div key={index} className='learncontentsa'>
                        <ApiIcon className='bulleticonsa' />
                        <p className='fontsizessa'>{item}</p>
                      </div>
                    ))}
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      {/* <----------- Clear doubts anytime and anywhere   --------------> */}
      <Fade bottom big>
        <div className='learnvideobge'>
          <div className='videoslearne'>
            <div className='divwidthe'>
              <div className='learncontaine'>
                <Fade left big>
                  <div className='leftsidecontente'>
                    <h1 className='learnclassese'>{featuresData[4].title}</h1>
                    {featuresData[4].content.map((item, index) => (
                      <div key={index} className='learncontente'>
                        <ApiIcon className='bulleticone' />
                        <p className='fontsizee'>{item}</p>
                      </div>
                    ))}
                  </div>
                </Fade>
                <Fade right big duration={getAnimationDuration()}>
                  <div className='learnrighte'>
                    <img src={cleardoubts} className='learnrightimagee' alt='cleardoubts' />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      {/* <------ Language Support  -------> */}
      <Fade bottom big>
        <div className='ouestionanswbgsa'>
          <div className='videoslearnsa'>
            <div className='divwidthsa'>
              <div className='learncontainsa'>
                <Fade left big>
                  <div className='learnrightsa'>
                    <img src={languagesupport} className='imagelearnrightsa' alt='language' />
                  </div>
                </Fade>
                <Fade right big duration={getAnimationDuration()}>
                  <div className='leftsidecontentsa'>
                    <h1 className='learnclassesesa'> {featuresData[5].title}</h1>
                    {featuresData[5].content.map((item, index) => (
                      <div key={index} className='learncontentsa'>
                        <ApiIcon className='bulleticonsa' />
                        <p className='fontsizessa'>{item}</p>
                      </div>
                    ))}
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      {/* <----------- Learn from Anywhere  --------------> */}
      <Fade bottom big>
        <div className='learnvideobge'>
          <div className='videoslearne'>
            <div className='divwidthe'>
              <div className='learncontaine'>
                <Fade left big>
                  <div className='leftsidecontente'>
                    <h1 className='learnclassese'>{featuresData[6].title}</h1>
                    {featuresData[6].content.map((item, index) => (
                      <div key={index} className='learncontente'>
                        <ApiIcon className='bulleticone' />
                        <p className='fontsizee'>{item}</p>
                      </div>
                    ))}
                  </div>
                </Fade>
                <Fade right big duration={getAnimationDuration()}>
                  <div className='learnrighte'>
                    <img src={learnfromanywhere} className='learnrightimagee' alt='learn' />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      {/* <----------- Device Support --------------> */}
      <Fade bottom big>
        <div className='ouestionanswbgs'>
          <div className='videoslearns'>
            <div className='divwidths'>
              <div className='learncontains'>
                <Fade left big>
                  <div className='learnrights'>
                    <img src={devicesuppor} className='imagelearnrights' alt='device support' />
                  </div>
                </Fade>
                <Fade right big duration={getAnimationDuration()}>
                  <div className='leftsidecontents'>
                    <h1 className='learnclasseses'>{featuresData[7].title}</h1>
                    {featuresData[7].content.map((item, index) => (
                      <div key={index} className='learncontents'>
                        <ApiIcon className='bulleticons' />
                        <p className='fontsizess'>{item}</p>
                      </div>
                    ))}
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      {/* <--- App Demo Videos ---> */}
      <div className='ourappdemo'>
        <div className='appdemoes'>
          <img
            src={appDemoImage}
            className={i18n.language !== "en" ? "demoapp appdemoTamil" : "demoapp"}
            alt='demo'
            ref={block3Ref}
          />
        </div>
      </div>
      <div className='demovideossec'>
        <div className='playerdiv'>
          <video
            style={{ width: "100%", borderRadius: "20px", border: "1px solid black" }}
            controls
            controlsList='nodownload'
          >
            <source src={demoVideoURL} className='reactPlayer' />
          </video>
        </div>
      </div>
      <div className='footercolor' ref={block4Ref}>
        <div className='location'>
          <LocationOnIcon className='locationicon' />
          <p className='address'>
            {t("HEAD OFFICE").split(" ").join("\u00A0")}

            <span className='addressfontsize'>
              {t("No. 1/1,")}
              <br /> {t("Nathan Street, Adikalapuram, Palayamkottai, Tirunelveli - 627002")} <br />
              +91 2242424242, <br /> +91 911313131
            </span>
          </p>
        </div>
        <div className='footersicon'>
          <div className='topsocialicon'>
            <Icon
              icon='ic:round-facebook'
              className='social'
              onClick={() =>
                window.open(
                  "https://www.facebook.com/people/Netcom-Computers-Pvt-Ltd/100083369047697/",
                )
              }
            />
            <Icon
              icon='mdi:instagram'
              className='social'
              onClick={() => window.open(" https://www.instagram.com/")}
            />
            <Icon
              icon='ph:twitter-logo'
              className='social'
              onClick={() => window.open("  https://twitter.com/NetcomTvl")}
            />
          </div>
          <div className='bottomsocialicon'>
            <Icon
              icon='carbon:logo-linkedin'
              className='social'
              onClick={() => window.open("  https://in.linkedin.com/")}
            />
            <Icon
              icon='ant-design:whats-app-outlined'
              className='social'
              onClick={() => window.open("  https://www.whatsapp.com/")}
            />
            <Icon
              icon='circum:youtube'
              className='social'
              onClick={() =>
                window.open(" https://www.youtube.com/channel/UCsLbLIoP2MLN-v87Z7hk9ew")
              }
            />
          </div>
        </div>
        <div className='locate'>
          <LocationOnIcon className='locationicon' />
          <p className='address'>
            {t("CORPORATE OFFICE").split(" ").join("\u00A0")} <br />
            <span className='addressfontsize'>
              {t("No. 5/3,")}
              <br />
              {t("Second Floor,")}
              <br />
              {t("Kush Kumar Road, Nungambakkam, Chennai - 600 034.")}
              <br />
              {t("Chennai - 600 034.")}
              <br />
              044 - 421 253 69
            </span>
          </p>
        </div>
      </div>
      <div className='footerdiv'>
        <div className='fottersocialmediadiv'>
          <div className='located'>
            <Icon
              icon='material-symbols:phone-in-talk'
              className='socials'
              onClick={() => window.open("tel:+917305023044")}
            />
          </div>
          <div className='footerbar'>
            {" "}
            <img src={footerplaystore} alt='footer play store' className='playstorfooters' />{" "}
          </div>
          <div className='locating'>
            <Icon
              icon='ic:twotone-mail-outline'
              className='socials'
              onClick={() => window.open("mailto:development@ncpli.com")}
            />
          </div>
        </div>
        <div className='horizontalLinemain'>
          <hr className='horizontalLine'></hr>
        </div>
      </div>
      <div className='copyright'>
        <div className='copyrightsecdiv'>
          <div className='getfullyear'>
            © {date.getFullYear()}| {t("Gillgall.")} | {t("All rights reserved")}{" "}
          </div>
          <div className='texbox'>
            {footerData.map((data, index) => (
              <span key={index} className='privacypolicy' onClick={() => window.open(data.link)}>
                {data.name}{" "}
              </span>
            ))}
          </div>
        </div>
      </div>
      <AdsComponent dataAdSlot='slot-1' />
      <TrailExpiry />
    </div>
  );
}
export default Landingpage;
