import React, { useEffect, useState } from "react";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { APIClient } from "../../utils/APIClient";
import axios from "axios";
import PreUploadedClassList from "./components/PreUploadedClassList";
import { useTranslation } from "react-i18next";
const PreUploadClasses = () => {
  const { t } = useTranslation(["translationPreUploadClasses"]);
  const navigate = useNavigate();
  const location = useLocation();
  const subjectColor = location?.state?.subjectColor;
  const [preUploadClasses, setPreUploadClasses] = useState([]);
  const subjectname = location?.state?.subject || "";

  const getAllPreuploadClasses = () => {
    const token = localStorage.getItem("token");
    const payload = {
      page: 1,
      itemCount: 10,
      subject: subjectname,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/getAllPreuploadClass`, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const receivedData = response.data.data;
        const filteredData = receivedData.filter((item) => item.subject === subjectname);
        setPreUploadClasses(filteredData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllPreuploadClasses();
  }, [subjectname]);

  return (
    <div>
      <SubjectNavbar />
      <Box sx={{ padding: "20px 30px" }}>
        <Box
          sx={{
            backgroundColor: subjectColor,
            borderRadius: "10px 10px 50px 10px",
            display: "flex",
            gap: "10px",
            padding: "10px",
          }}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
            sx={{ p: 0, m: 0 }}
          >
            <ArrowBack sx={{ color: "#fff" }} />
          </IconButton>

          <Typography sx={{ fontSize: "18px", color: "#fff", fontWeight: 600 }}>
            {t("Pre Uploaded Classes")}
          </Typography>
        </Box>
        {preUploadClasses.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {preUploadClasses.map((item, index) => (
              <PreUploadedClassList key={index} data={item} />
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "85vh",
              justifyContent: "center",
            }}
          >
            <Typography variant='h2' color='textSecondary'>
              {t("There are no pre-uploaded classes available right now.")}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default PreUploadClasses;
