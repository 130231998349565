import { AppBar, Box, Button, Card, Stack, Typography, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import Colors from "../../utils/Colors";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import "./QuizPage.css";
import axios from "axios";
import { APIClient } from "../../utils/APIClient";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";
const QuizPage = () => {
  const { t } = useTranslation(["translationQuizPage"]);
  const navigate = useNavigate();
  const location = useLocation();
  const unitMCQData = location?.state?.questionSet || [];
  const questionname = location?.state?.questionNames || "";
  const whichyear = location?.state?.year || "";
  const questionSetMainID = location?.state?.questionSetMainID || "";
  const [indexValue, setIndexValue] = useState(0);
  const [answerButton, setAnswerButton] = useState(true);
  const [selectionColor, setSelectionColor] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("#8BC5EA");
  const [selectBgColor, setSelectBgColor] = useState("");
  const [correctAnsCount, setCorrectAnsCount] = useState(0);
  const [validationCheck, setValidationCheck] = useState("");
  const [userAnswers, setUserAnswers] = useState([]);
  const [questionIDUserAnswer, setQuestionIDUserAnswer] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  const matchingKey = Object.keys(unitMCQData[indexValue]).find((key) =>
    key.startsWith("question"),
  );

  const answerclik = (index, value) => {
    setAnswerButton(false);
    setSelectionColor(index);
    setValidationCheck(value);
    setBackgroundColor("#8BC5EA");
  };

  const ansCheck = {
    option1: "A",
    option2: "B",
    option3: "C",
    option4: "D",
  };

  const nextbtnfun = () => {
    if (selectionColor === null) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: Colors.globalColorThemeColor,
        title: t("Please Select The Option"),
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
      return;
    }

    const selectedOptionIndex = selectionColor;
    const correctOptionIndex = unitMCQData[indexValue].correctOption.charCodeAt(0) - 65;
    const correct = selectedOptionIndex === correctOptionIndex;

    setQuestionIDUserAnswer((prev) => [
      ...(Array.isArray(prev) ? prev : []),
      {
        questionId: unitMCQData[indexValue].questionId,
        userSelectedOption: ansCheck[`option${selectedOptionIndex + 1}`],
      },
    ]);

    const options = ["option1", "option2", "option3", "option4"];

    setUserAnswers((prev) => [
      ...(Array.isArray(prev) ? prev : []),
      {
        question: unitMCQData[indexValue].question,
        selectedAnswer: ansCheck[`option${selectedOptionIndex + 1}`],
        correctAnswer: unitMCQData[indexValue].correctOption,
        isCorrect: correct,
        options: options.map((option, index) => ({
          option: ansCheck[option],
          value: unitMCQData[indexValue][option],
          isSelected: index === selectedOptionIndex,
          isCorrect: index === correctOptionIndex,
        })),
      },
    ]);

    if (unitMCQData.length === indexValue + 1) {
      const finalQuestionIDUserAnswer = [
        ...questionIDUserAnswer,
        {
          questionId: unitMCQData[indexValue].questionId,
          userSelectedOption: ansCheck[`option${selectedOptionIndex + 1}`],
        },
      ];

      const finalUserAnswers = [
        ...userAnswers,
        {
          question: unitMCQData[indexValue].question,
          selectedAnswer: ansCheck[`option${selectedOptionIndex + 1}`],
          correctAnswer: unitMCQData[indexValue].correctOption,
          isCorrect: correct,
          options: options.map((option, index) => ({
            option: ansCheck[option],
            value: unitMCQData[indexValue][option],
            isSelected: index === selectedOptionIndex,
            isCorrect: index === correctOptionIndex,
          })),
        },
      ];

      const payload = {
        id: questionSetMainID,
        userAnswers: finalQuestionIDUserAnswer,
        type: questionname,
      };
      setLoading(true);
      axios
        .post(`${APIClient().API_BASE_URL}/api/storeMarks`, payload, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          const responseData = response.data.datas;
          setLoading(false);
          navigate("/ExamResult", {
            replace: true,
            state: {
              correctAnsCount: responseData.correct,
              wrongAnswer: responseData.wrong,
              marksGained: responseData.markGained,
              highestMarks: responseData.previousHightestMarks,
              userAnswers: finalUserAnswers,
              questionType: questionname,
              year: whichyear,
            },
          });
        });
      return;
    }

    if (correct) {
      setCorrectAnsCount(correctAnsCount + 1);
    }
    setSelectionColor(null);
    setIndexValue(indexValue + 1);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(delay);
  }, []);

  const onEndTestBtnClick = () => {
    Swal.fire({
      title: t("End test"),
      text: t("Are You sure want to end test!"),
      showCancelButton: true,
      confirmButtonText: t("Yes"),
      cancelButtonText: t("No"),
      confirmButtonColor: Colors.globalColorThemeColor,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(-1);
      }
    });
  };

  return (
    <div>
      <SubjectNavbar />
      {loading ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
        >
          <CircularProgress color='inherit' />
        </Box>
      ) : (
        <Box sx={{ padding: "30px 30px" }}>
          <div>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              className='neetquizequestionDteail'
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: {
                    xs: "18px",
                    md: "20px",
                  },
                }}
              >
                {t("Question No")}: {indexValue + 1}/{unitMCQData.length}
              </Typography>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                  alignItems: "center",
                }}
                className='neetquizenameyearfirst'
              >
                <Typography sx={{ fontWeight: "600", fontSize: "20px", marginRight: 2 }}>
                  {whichyear}
                </Typography>
                <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>{questionname}</Typography>
              </Box>

              <Button
                variant='contained'
                sx={{ height: "40px", width: "150px", background: "red" }}
                onClick={onEndTestBtnClick}
              >
                {t("End Test")}
              </Button>
            </Box>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                },
                alignItems: "center",
                justifyContent: "center",
                height: "5vh",
                gap: "5px",
              }}
              className='neetquizenameyear'
            >
              <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>{whichyear}</Typography>
              <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>{questionname}</Typography>
            </Box>
            <h3>
              <ReactQuill
                value={unitMCQData[indexValue]["question"]}
                readOnly={true}
                modules={{ toolbar: false }}
                style={{
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                }}
                // className='ReactQuillContainer'
              />
            </h3>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card
                onClick={() => answerclik(0, unitMCQData[indexValue]["option1"])}
                sx={{
                  border: "1px solid grey",
                  width: "99%",
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  zIndex: 99,
                  bgcolor:
                    selectionColor === 0
                      ? backgroundColor
                      : selectBgColor === unitMCQData[indexValue]["option1"]
                        ? "green"
                        : "white",
                }}
              >
                <Typography variant='body1' sx={{ fontWeight: "bold", marginLeft: 1 }}>
                  A.
                </Typography>

                <ReactQuill
                  value={unitMCQData[indexValue]["option1"]}
                  readOnly={true}
                  modules={{ toolbar: false }}
                  style={{
                    border: "none",
                    display: "flex",
                    alignSelf: "center",
                    marginLeft: "15px",
                  }}
                  // className='ReactQuillContainer'
                />
              </Card>
              <Card
                onClick={() => answerclik(1, unitMCQData[indexValue]["option2"])}
                sx={{
                  border: "1px solid grey",
                  width: "99%",
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  zIndex: 99,
                  bgcolor:
                    selectionColor === 1
                      ? backgroundColor
                      : selectBgColor === unitMCQData[indexValue]["option2"]
                        ? "green"
                        : "white",
                }}
              >
                <Typography variant='body1' sx={{ fontWeight: "bold", marginLeft: 1 }}>
                  B.
                </Typography>

                <ReactQuill
                  value={unitMCQData[indexValue]["option2"]}
                  readOnly={true}
                  modules={{ toolbar: false }}
                  style={{
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "15px",
                  }}
                  // className='ReactQuillContainer'
                />
              </Card>
              <Card
                onClick={() => answerclik(2, unitMCQData[indexValue]["option3"])}
                sx={{
                  border: "1px solid grey",
                  width: "99%",
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  zIndex: 99,
                  bgcolor:
                    selectionColor === 2
                      ? backgroundColor
                      : selectBgColor === unitMCQData[indexValue]["option3"]
                        ? "green"
                        : "white",
                }}
              >
                <Typography variant='body1' sx={{ fontWeight: "bold", marginLeft: 1 }}>
                  C.
                </Typography>
                <ReactQuill
                  value={unitMCQData[indexValue]["option3"]}
                  readOnly={true}
                  modules={{ toolbar: false }}
                  style={{
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "15px",
                  }}
                  // className='ReactQuillContainer'
                />
              </Card>
              <Card
                onClick={() => answerclik(3, unitMCQData[indexValue]["option4"])}
                sx={{
                  border: "1px solid grey",
                  width: "99%",
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  zIndex: 99,
                  bgcolor:
                    selectionColor === 3
                      ? backgroundColor
                      : selectBgColor === unitMCQData[indexValue]["option4"]
                        ? "green"
                        : "white",
                }}
              >
                <Typography variant='body1' sx={{ fontWeight: "bold", marginLeft: 1 }}>
                  D.
                </Typography>
                <ReactQuill
                  value={unitMCQData[indexValue]["option4"]}
                  readOnly={true}
                  modules={{ toolbar: false }}
                  style={{
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "15px",
                  }}
                  // className='ReactQuillContainer'
                />
              </Card>
            </div>

            <Stack
              sx={{ marginTop: "15px" }}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Button
                variant='contained'
                sx={{
                  height: "40px",
                  width: "135px",
                  display: indexValue === 0 ? "none" : "block",
                }}
                onClick={() => setIndexValue(indexValue - 1)}
              >
                {t("Previous")}
              </Button>
              <Button
                variant='contained'
                sx={{
                  height: "40px",
                  width: "135px",
                }}
                onClick={nextbtnfun}
              >
                {indexValue === unitMCQData.length - 1 ? t("Submit") : t("Next")}
              </Button>
            </Stack>
          </div>
        </Box>
      )}
    </div>
  );
};

export default QuizPage;
