import { configureStore } from "@reduxjs/toolkit";
import registerDataReducer from "./registerDataSlice";
import loginDataReducer from "./loginDataSlice";
import unitsDataReducer from "./unitsDataSlice";
import unitTopicsReducer from "./unitTopicsSlice";
import unitQAsReducer from "./unitQAsSlice";
import unitMCQsReducer from "./unitMCQsSlice";
import profileDataReducer from "./profileDataSlice";
export default configureStore({
  reducer: {
    registerData: registerDataReducer,
    loginData: loginDataReducer,
    profileData: profileDataReducer,
    unitsData: unitsDataReducer,
    unitTopics: unitTopicsReducer,
    unitQAs: unitQAsReducer,
    unitMCQs: unitMCQsReducer,
  },
});
