import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../unitstyle.css";
import { CurrentBalanceMCQs } from "../../../redux/unitMCQsSlice";
import { useSelector } from "react-redux";
import emptyques from "../../Units/Image/basket-empty.png";
import EasyMediumHard from "./EasyMediumHard";
import arrayShuffle from "array-shuffle";
import { ShouldRender } from "should-render";
import { useTranslation } from "react-i18next";
const UnitsQuiz = ({ mcqList }) => {
  const { t } = useTranslation(["translationUnitsQuize"]);
  const [showQuizSelection, setShowQuizSelection] = useState(true);
  const [showQuestionsBlock, setShowQuestionsBlock] = useState(false);
  const [mcqDataforQuiz, setMcqDataforQuiz] = useState([]);
  const unitCurrent = useSelector(CurrentBalanceMCQs);

  const handleButtonClick = () => {
    let unitTemp = arrayShuffle(mcqList);
    setMcqDataforQuiz(unitTemp);
    setShowQuizSelection(!showQuizSelection);
    setShowQuestionsBlock(!showQuestionsBlock);
  };

  useEffect(() => {
    setShowQuizSelection(true);
    setShowQuestionsBlock(false);
  }, [mcqList]);

  return (
    <div>
      <ShouldRender if={mcqList[0]}>
        <div>
          {showQuizSelection && (
            <div className='unitQuizes'>
              <Button
                variant='contained'
                className='eastbtn'
                style={{
                  backgroundColor: "#50cb94",
                  width: "300px",
                  height: "50px",
                  fontSize: "20px",
                }}
                onClick={handleButtonClick}
              >
                {t("EASY")}
              </Button>
              <Button
                variant='contained'
                style={{
                  backgroundColor: "#fea36c",
                  width: "300px",
                  height: "50px",
                  fontSize: "20px",
                }}
                onClick={handleButtonClick}
              >
                {t("MEDIUM")}
              </Button>
              <Button
                variant='contained'
                style={{
                  backgroundColor: "#d53f62",
                  width: "300px",
                  height: "50px",
                  fontSize: "20px",
                }}
                onClick={handleButtonClick}
              >
                {t("HARD")}
              </Button>
            </div>
          )}
          {showQuestionsBlock && <EasyMediumHard mcqDataforQuiz={mcqDataforQuiz} />}
        </div>
      </ShouldRender>

      <ShouldRender if={!mcqList[0]}>
        <div className='emptyques'>
          <img className='emptyquesimage' alt={"Empty Quiz Img"} src={emptyques} />
          <h5>{t("Quizs are not yet available. We Will provide them soon")}</h5>
        </div>
      </ShouldRender>
    </div>
  );
};
export default UnitsQuiz;
