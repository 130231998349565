import React, { useEffect, useState } from "react";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { APIClient } from "../../utils/APIClient";
import axios from "axios";
import LiveClassList from "./components/LiveClassList";
import { useTranslation } from "react-i18next";
const LiveClassPage = () => {
  const { t } = useTranslation(["translationLiveClassPage"]);
  const [liveClasses, setLiveClasses] = useState([]);
  const [time, setTime] = useState({
    startDateTime: null,
    endDateTime: null,
  });

  const [dialogFormData, setDialogFormData] = useState({
    Subject: "",
    Title: "",
    Description: "",
    AuthorName: "",
    MeetLink: "",
    TopicImage: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const subjectColor = location.state.subjectColor;
  const subjectname = location?.state?.subject || "";
  const getAllLiveClasses = () => {
    const token = localStorage.getItem("token");
    const payload = {
      page: 1,
      itemCount: 10,
      subject: subjectname,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/getAllLiveClass`, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const receivedData = response.data.data;
        const filteredData = receivedData.filter((item) => item.subject === subjectname);
        setLiveClasses(filteredData);
        setDialogFormData({
          Subject: "",
          Title: "",
          Description: "",
          AuthorName: "",
          MeetLink: "",
          TopicImage: "",
        });
        setTime({
          startDateTime: null,
          endDateTime: null,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllLiveClasses();
  }, [subjectname]);

  return (
    <div>
      <SubjectNavbar />
      <Box sx={{ padding: "20px 30px" }}>
        <Box
          sx={{
            backgroundColor: subjectColor,
            borderRadius: "10px 10px 50px 10px",
            display: "flex",
            gap: "10px",
            padding: "10px",
          }}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
            sx={{ p: 0, m: 0 }}
          >
            <ArrowBack sx={{ color: "#fff" }} />
          </IconButton>

          <Typography sx={{ fontSize: "18px", color: "#fff", fontWeight: 600 }}>
            {t("Live Classes")}
          </Typography>
        </Box>
        {liveClasses.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {liveClasses.map((item, index) => (
              <LiveClassList key={index} data={item} />
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "85vh",
              justifyContent: "center",
            }}
          >
            <Typography variant='h2' color='textSecondary'>
              {t("There are no live classes available right now.")}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default LiveClassPage;
