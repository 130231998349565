import React, { useEffect, useState } from "react";
import "./MockExamQuestions.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Card, Grid, Typography, Stack, IconButton, Pagination } from "@mui/material";
import { APIClient } from "../../utils/APIClient";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Colors from "../../utils/Colors";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import HeaderBox from "../../common/components/HeaderBox";
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from "react-i18next";
export default function MockExamQuestionsPage() {
  const { t } = useTranslation(["translationMockExamQuestionsPage"]);
  const navigate = useNavigate();
  const location = useLocation();
  const mockexamquestions = location?.state?.questionType || {};
  const selectSubject = location.state.subject;
  const subjectColor = location.state.subjectColor;
  const [mockExamList, setMockExamList] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const token = localStorage.getItem("token");
  const getAllTest = () => {
    const payload = {
      questionType: mockexamquestions,
      page: 0,
      itemsPerPage: 0,
      subject: selectSubject,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/getAllTest`, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const receivedMockExam = response.data.data;

        if (selectSubject && receivedMockExam.length > 0) {
          // const filteredQuestions = receivedMockExam.filter(
          //   (item) => item.subject === selectSubject,
          // );
          setMockExamList(receivedMockExam);
        }

        setPageLoading(false);
      })
      .catch((error) => {
        setPageLoading(false);
        console.log(error);
      });
  };
  const changeCPage = (id) => {
    setCurrentPage(id);
  };
  // <--- Pagination    --->
  const recordsPerPage = 9;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = mockExamList.slice(firstIndex, lastIndex);
  const npage = Math.ceil(mockExamList.length / recordsPerPage);
  useEffect(() => {
    getAllTest();
  }, []);

  const onStartTestClick = (mockExamName, subject, questionsCount) => {
    if (questionsCount === 0) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: Colors.globalColorThemeColor,
        title: "No Question Available",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    } else {
      const payload = {
        questionType: "Mock Exam Questions",
        subject: subject,
        mockExamName: mockExamName,
        page: 0,
        itemCount: 0,
      };
      axios.post(`${APIClient().API_BASE_URL}/api/getQuestionSet`, payload).then((response) => {
        navigate("/quizPage", {
          state: {
            questionSet: response.data.data.questionSet,
            questionType: mockExamName,
            questionSetMainID: response.data.data.id,
            questionNames: "Mock Exam Questions",
          },
        });
      });
    }
  };

  const viewMockExamList = (item) => {
    if (item.questionsCount === 0) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: Colors.globalColorThemeColor,
        title: "No Question Available",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    } else {
      navigate("/questionDetailPage", {
        state: {
          questionType: item.questionType,
          mockExamName: item.mockExamName,
          subject: item.subject,
          subjectColor,
        },
      });
    }
  };
  const onUnlockBtnClick = () => {
    navigate("/neetsubscriptionplans");
  };
  return (
    <>
      <SubjectNavbar />
      <Box sx={{ padding: "20px 30px" }}>
        <HeaderBox bgcolor={subjectColor} title={t("Mock Exam Questions")} backBtn={true} />

        <Box>
          {pageLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Grid container spacing={4} mt={1}>
              {mockExamList.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "100%",
                  }}
                >
                  <Typography variant='h1' sx={{ fontSize: "22px" }}>
                    No mock exams available for this subject.
                  </Typography>
                </div>
              ) : (
                records.map((item, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <Card
                      elevation={5}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "15px",
                        // gap: "40px",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            textAlign: "center",
                            fontWeight: "600",
                            // height: "80px",
                          }}
                        >
                          {item.mockExamName}
                        </Typography>

                        <Stack direction={"row"} justifyContent={"space-between"} mt={2}>
                          <Stack direction={"column"} alignItems={"center"}>
                            <Typography sx={{ fontSize: "14px" }}>{t("Subject")}</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                              {item.subject}
                            </Typography>
                          </Stack>

                          <Stack direction={"column"} alignItems={"center"}>
                            <Typography sx={{ fontSize: "14px" }}>{t("Questions")}</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                              {item.questionsCount}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"space-between"} mt={2}>
                          <Stack direction={"column"} alignItems={"flex-start"}>
                            <Typography sx={{ fontSize: "14px" }}>Highest Marks</Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                              {item.highestMark || t("Not Attended Yet")}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>
                      {item.isLocked === false ? (
                        <Stack
                          direction={"row"}
                          gap={2}
                          sx={{ width: "100%", marginBottom: "10px", marginTop: "30px" }}
                        >
                          <Button
                            variant='outlined'
                            sx={{
                              color: subjectColor,
                              height: "40px",
                              width: "100%",
                              fontSize: "13px",
                              fontWeight: "bold",
                              textTransform: "none",
                            }}
                            onClick={() => viewMockExamList(item)}
                          >
                            {t("View Questions")}
                          </Button>
                          <Button
                            variant='outlined'
                            sx={{
                              color: "#fff",
                              height: "40px",
                              width: "100%",
                              backgroundColor: subjectColor,
                              fontSize: "13px",
                              fontWeight: "bold",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: subjectColor,
                                fontSize: "13px",
                                fontWeight: "bold",
                                textTransform: "none",
                              },
                            }}
                            onClick={() =>
                              onStartTestClick(item.mockExamName, item.subject, item.questionsCount)
                            }
                          >
                            {t("Start Exam")}
                          </Button>
                        </Stack>
                      ) : (
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                          <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <LockIcon />
                          </Box>

                          <Button
                            variant='contained'
                            sx={{
                              backgroundColor: "#DAA520",
                              fontSize: "15px",
                              marginTop: "15px",
                            }}
                            onClick={onUnlockBtnClick}
                          >
                            {t("unlock")}
                          </Button>
                        </Box>
                      )}
                    </Card>
                  </Grid>
                ))
              )}
            </Grid>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination count={npage} page={currentPage} onChange={(e, i) => changeCPage(i)} />
      </Box>
    </>
  );
}
