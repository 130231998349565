import { Button, Card, Grow, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navebar from "../../Navbar/Navebar";
import tamilnadu from "./images/TamilNadu_Logo.png";
import centeral from "./images/CBSElogo.png";
import indiancertificte from "./images/icseLogo.png";
import { APIClient } from "../../utils/APIClient";
import neet from "./images/neet.png";
import "./registerstyle.css";
import { useSelector, useDispatch } from "react-redux";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  changeBoard,
  changeMedium,
  CurrentBoard,
  CurrentMedium,
  changeClass,
} from "../../redux/registerDataSlice";
// import {
//   changeBoard,
//   changeMedium,
//   CurrentBoard,
//   CurrentMedium,
//   changeClass,
// } from "../../redux/profileDataSlice";
import Colors from "../../utils/Colors";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

function SelectBoard() {
  const { t } = useTranslation(["translationSelectBoard"]);
  const [
    subjectList,
    // setSubjectList
  ] = useState([]);
  const location = useLocation();
  const loggedInDataObj = location.state || {};
  const token = localStorage.getItem("token");
  const { loginData, ChangeBoardAndClass, ChangeToStateBoard, ChangeToNeet } = loggedInDataObj;
  const navigate = useNavigate();
  const getBoard = useSelector(CurrentBoard);
  const getMedium = useSelector(CurrentMedium);
  const dispatch = useDispatch();
  const boardselection = [
    {
      img: tamilnadu,
      altmsg: "TNLogo",
      board: t("TAMIL NADU STATE BOARD"),
      cltdata: "sb",
    },
    {
      img: neet,
      board: t("GILLGALL NEET"),
      cltdata: "neet",
    },
    {
      img: centeral,
      altmsg: "CBSELogo",
      board: t("CENTRAL BOARD OF SECONDARY EDUCATION"),
      cltdata: "cb",
    },
    {
      img: indiancertificte,
      altmsg: "INCSELogo",
      board: t("INDIAN CERTIFICATE OF SECONDARY EDUCATION"),
      cltdata: "ic",
    },
  ];
  const selectmedium = [
    {
      img: getBoard === "sb" ? tamilnadu : getBoard === "cb" ? centeral : indiancertificte,
      altmsg: "logo",
      medium: getBoard === "sb" ? "தமிழ் வழி கல்வி" : "English Medium",
      cltdata: getBoard === "sb" ? "tm" : "em",
    },
    {
      img: getBoard === "sb" ? tamilnadu : getBoard === "cb" ? centeral : indiancertificte,
      altmsg: "logo",
      medium: getBoard === "cb" ? "Hindi" : "English",
      cltdata: getBoard === "cb" ? "hm" : "em",
    },
  ];

  const boardSelectionFn = (value) => {
    if (value === "sb" || value === "neet" || value === "cb") {
      dispatch(changeBoard(value));
    } else {
      return false;
    }
  };

  const mediumSelectionFn = (value, index) => {
    dispatch(changeMedium(value));
  };
  const onNextBtnClick = () => {
    if (getMedium === "" && getBoard === "sb") {
      enqueueSnackbar("Please Select The Medium", { variant: "error" });
    } else {
      if (token && getBoard === "neet") {
        axios
          .get(`${APIClient().API_BASE_URL}/api/getUserSubscriptionDetails`, {
            headers: {
              Authorization: `${token}`,
            },
          })
          .then((res) => {
            const neetSubscriptionData = res.data.data.neetSubscription;
            const { planName } = neetSubscriptionData;
            if (!planName) {
              navigate("/neetsubscribe");
            } else {
              const payload = {
                board: "neet",
              };
              axios
                .put(`${APIClient().API_BASE_URL}/api/updateBoardAndClass`, payload, {
                  headers: {
                    Authorization: `${token}`,
                  },
                })
                .then((res) => {
                  if (res.data.success) {
                    navigate("/home");
                  }
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
              navigate("/home");
            }
          });
      } else if (token && getBoard === "sb") {
        navigate("/chooseclass", {
          state: { getBoard, getMedium, loginData, ChangeToStateBoard },
        });
      } else if (getBoard === "neet") {
        navigate("/register");
      } else {
        navigate("/chooseclass", { state: { getBoard, getMedium } });
      }
    }
  };
  return (
    <div>
      <Navebar title={t("Select Board")} />
      <div className='board'>
        <div className='logos'>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 600,
              color: "#FFFFFF",
              border: "1px solid #FFFFFF",
              mt: 1,
            }}
          >
            Choose Your Board
          </Typography>
          {boardselection.map((items, index) => (
            <Card
              key={index}
              className='cards'
              onClick={() => boardSelectionFn(items.cltdata, index)}
              elevation={5}
              sx={{
                cursor: "pointer",
                bgcolor: items.cltdata === getBoard ? "#DFF3FE" : "white",
                border:
                  items.cltdata === getBoard
                    ? "2px solid " + Colors.globalColorThemeColor
                    : "black",
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ width: "100%" }}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <img src={items.img} alt={items.altmsg} className='images' />
                  <Typography sx={{ fontSize: "18px", fontWeight: 500, ml: 1 }}>
                    {items.board}
                  </Typography>
                </Stack>
                <LockOutlinedIcon
                  sx={{
                    display:
                      items.cltdata !== "sb" && items.cltdata !== "neet" && items.cltdata !== "cb"
                        ? "inherit"
                        : "none",
                  }}
                />
              </Stack>
            </Card>
          ))}

          {getBoard === "sb" ? (
            <Grow in timeout={2000}>
              <div className='mediumlogo'>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    color: Colors.globalColorThemeColor,
                    mt: 1,
                  }}
                >
                  {t("Choose Your Medium")}
                </Typography>
                {selectmedium.map((items, index) => (
                  <Card
                    key={index}
                    className='cards'
                    elevation={5}
                    onClick={() => mediumSelectionFn(items.cltdata, index)}
                    sx={{
                      cursor: "pointer",
                      bgcolor: items.cltdata === getMedium ? "#DFF3FE" : "white",
                      border:
                        items.cltdata === getMedium
                          ? "2px solid " + Colors.globalColorThemeColor
                          : "black",
                    }}
                  >
                    <img src={items.img} alt={items.altmsg} className='images' />
                    <Typography sx={{ fontSize: "18px", fontWeight: 500, ml: 1 }}>
                      {items.medium}{" "}
                    </Typography>
                  </Card>
                ))}
              </div>
            </Grow>
          ) : (
            false
          )}
          {getBoard === "cb" ? (
            <Grow in timeout={2000}>
              <div className='mediumlogo'>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    color: Colors.globalColorThemeColor,
                    mt: 1,
                  }}
                >
                  {t("Choose Your Medium")}
                </Typography>
                {selectmedium.map((items, index) => (
                  <Card
                    key={index}
                    className='cards'
                    elevation={5}
                    onClick={() => mediumSelectionFn(items.cltdata, index)}
                    sx={{
                      cursor: "pointer",
                      bgcolor: items.cltdata === getMedium ? "#DFF3FE" : "white",
                      border:
                        items.cltdata === getMedium
                          ? "2px solid " + Colors.globalColorThemeColor
                          : "black",
                    }}
                  >
                    <img src={items.img} alt={items.altmsg} className='images' />
                    <Typography sx={{ fontSize: "18px", fontWeight: 500, ml: 1 }}>
                      {items.medium}{" "}
                    </Typography>
                  </Card>
                ))}
              </div>
            </Grow>
          ) : (
            false
          )}
        </div>
      </div>
      {getBoard !== "" ? (
        <Grow in timeout={2000}>
          <div className='nextbtn'>
            <Button
              variant='contained'
              onClick={onNextBtnClick}
              className='nextbutton'
              sx={{
                backgroundColor: Colors.globalColorThemeColor,
                "&:hover": {
                  backgroundColor: Colors.globalColorThemeColor,
                },
              }}
            >
              {t("Next")}
            </Button>
          </div>
        </Grow>
      ) : (
        false
      )}
    </div>
  );
}
export default SelectBoard;
