import {
  Stack,
  Box,
  Container,
  CircularProgress,
  styled,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./style.css";
import { APIClient } from "../../utils/APIClient";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { setBookmarkUnitTopics } from "../../redux/unitTopicsSlice";
import { useDispatch, useSelector } from "react-redux";
import banner1 from "../../assets/banners/banner_1.png";
import banner1Tamil from "../../assets/banners/banner1Tamil.png";
import banner2 from "../../assets/banners/banner_2.png";
import banner2Tamil from "../../assets/banners/banner2Tamil.png";
import banner3 from "../../assets/banners/banner_3.png";
import banner3Tamil from "../../assets/banners/banner3Tamil.png";
import banner4 from "../../assets/banners/banner_4.png";
import banner4Tamil from "../../assets/banners/banner4Tamil.png";
import banner5 from "../../assets/banners/banner_5.png";
import banner5Tamil from "../../assets/banners/banner5Tamil.png";
import banner6 from "../../assets/banners/banner_6.png";
import banner6Tamil from "../../assets/banners/banner6Tamil.png";
import banner8 from "../../assets/banners/banner_8.png";
import banner8Tamil from "../../assets/banners/banner8Tamil.png";
import banner10 from "../../assets/banners/banner_10.png";
import banner10Tamil from "../../assets/banners/banner10Tamil.png";
import banner11 from "../../assets/banners/banner_11.png";
import banner11Tamil from "../../assets/banners/banner11Tamil.png";
import tab1 from "../../assets/banners/tabbanner_1.png";
import tabbanner1Tamil from "../../assets/banners/tabbanner1Tamil.png";
import tab2 from "../../assets/banners/tabbanner_2.png";
import tabbanner2Tamil from "../../assets/banners/tabbanner2Tamil.png";
import tab3 from "../../assets/banners/tabbanner_3.png";
import tabbanner3Tamil from "../../assets/banners/tabbanner3Tamil.png";
import tab4 from "../../assets/banners/tabbanner_4.png";
import tabbanner4Tamil from "../../assets/banners/tabbanner4Tamil.png";
import tab5 from "../../assets/banners/tabbanner_5.png";
import tabbanner5Tamil from "../../assets/banners/tabbanner5Tamil.png";
import tab6 from "../../assets/banners/tabbanner_6.png";
import tabbanner6Tamil from "../../assets/banners/tabbanner6Tamil.png";
import tab8 from "../../assets/banners/tabbanner_8.png";
import tabbanner8Tamil from "../../assets/banners/tabbanner8Tamil.png";
import tab9 from "../../assets/banners/tabbanner_9.png";
import tabbanner9Tamil from "../../assets/banners/tabbanner9Tamil.png";
import tab10 from "../../assets/banners/tabbanner_10.png";
import tabbanner10Tamil from "../../assets/banners/tabbanner10Tamil.png";
import Colors from "../../utils/Colors";
import Swal from "sweetalert2";
import gLogo from "../../assets/Gillgall.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeaderBox from "../../common/components/HeaderBox";
import { useTranslation } from "react-i18next";
import {
  changeMobileNo,
  changeBoard,
  changeClassName,
  changeEmail,
  changeUserID,
  changeName,
  changePlanName,
  changePaymentFrequency,
  changeMedium,
  changeFreeTrailStatus,
} from "../../redux/profileDataSlice";
import TrailExpiry from "../../common/modals/TrailExpiry/TrailExpiry";
import { CurrentBoard, CurrentClass, CurrentMedium } from "../../redux/registerDataSlice";
import { CurrentUserID, CurrentPlanName } from "../../redux/profileDataSlice";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { itemListEM, itemListTM } from "../../utils/constants";
import Liveclasses from "./Liveclasses/Liveclasses";
import PreuploadClass from "./PreuploadClass/PreuploadClass";

const Subjectpage = () => {
  const { t, i18n } = useTranslation(["translationSubjectpage"]);
  const isTamil = i18n.language === "ta";
  const bannerImage1 = isTamil ? banner1Tamil : banner1;
  const bannerImage2 = isTamil ? banner2Tamil : banner2;
  const bannerImage3 = isTamil ? banner3Tamil : banner3;
  const bannerImage4 = isTamil ? banner4Tamil : banner4;
  const bannerImage5 = isTamil ? banner5Tamil : banner5;
  const bannerImage6 = isTamil ? banner6Tamil : banner6;
  const bannerImage8 = isTamil ? banner8Tamil : banner8;
  const bannerImage10 = isTamil ? banner10Tamil : banner10;
  const bannerImage11 = isTamil ? banner11Tamil : banner11;
  const tabbanner1 = isTamil ? tabbanner1Tamil : tab1;
  const tabbanner2 = isTamil ? tabbanner2Tamil : tab2;
  const tabbanner3 = isTamil ? tabbanner3Tamil : tab3;
  const tabbanner4 = isTamil ? tabbanner4Tamil : tab4;
  const tabbanner5 = isTamil ? tabbanner5Tamil : tab5;
  const tabbanner6 = isTamil ? tabbanner6Tamil : tab6;
  const tabbanner8 = isTamil ? tabbanner8Tamil : tab8;
  const tabbanner9 = isTamil ? tabbanner9Tamil : tab9;
  const tabbanner10 = isTamil ? tabbanner10Tamil : tab10;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = location.state;
  const [loginData, setLoginData] = useState();
  const [loading, setLoading] = useState(true);
  const [subjectData, setSubjectData] = useState();
  const [board, setBoard] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const neetThroughClass = useSelector(CurrentClass);
  const neetThroughBoard = useSelector(CurrentBoard);
  const neetThroughMedium = useSelector(CurrentMedium);
  const userID = useSelector(CurrentUserID);
  const planName = useSelector(CurrentPlanName);
  const token = localStorage.getItem("token");
  const { fromNeetSubjectPage } = state ?? {};
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down("md"));
  const getItemColor = (title) => {
    const itemEM = itemListEM.find((o) => o.title === title);
    if (itemEM) return itemEM.color;

    const itemTM = itemListTM.find((o) => o.title === title);
    if (itemTM) return itemTM.color;

    return "defaultColor";
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const getSubjectDatas = () => {
    try {
      let dataToSent;
      if (fromNeetSubjectPage) {
        dataToSent = {
          className:
            neetThroughClass?.className.slice(-2) === "st"
              ? "class1th"
              : neetThroughClass?.className.slice(-2) === "nd"
                ? "class2th"
                : neetThroughClass.className.slice(-2) === "rd"
                  ? "class3th"
                  : neetThroughClass?.className,
          boardName: neetThroughBoard,
          tnBoardName: neetThroughMedium,
          mode: "desktop",
        };
      }
      axios
        .get(`${APIClient().API_BASE_URL}/api/getSubjectsForUser`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          // setSubjectData(itemListEM.filter((value) => res.data.user.includes(value.title)));
          if (loginData?.medium === "em") {
            const matchedResponse = res.data.subjectList.map((subject) => {
              const matchedItem = itemListEM.find((item) => item.title === subject.subjectName);
              return matchedItem
                ? { ...subject, img: matchedItem.img, color: matchedItem.color }
                : subject;
            });
            setSubjectData(matchedResponse);
          }
          if (loginData?.medium === "tm") {
            const matchedResponse = res.data.subjectList.map((subject) => {
              const matchedItem = itemListTM.find((item) => item.title === subject.subjectName);
              return matchedItem
                ? { ...subject, img: matchedItem.img, color: matchedItem.color }
                : subject;
            });
            setSubjectData(matchedResponse);
          } else if (loginData?.board === "neet") {
            const matchedResponse = res.data.subjectList.map((subject) => {
              const matchedItem = itemListEM.find((item) => item.title === subject.subjectName);
              return matchedItem
                ? { ...subject, img: matchedItem.img, color: matchedItem.color }
                : subject;
            });
            setSubjectData(matchedResponse);
          } else if (loginData?.medium === "hm") {
            const matchedResponse = res.data.subjectList.map((subject) => {
              const matchedItem = itemListTM.find((item) => item.title === subject.subjectName);
              return matchedItem
                ? { ...subject, img: matchedItem.img, color: matchedItem.color }
                : subject;
            });
            setSubjectData(matchedResponse);
          }
        });
    } catch (error) {
      console.log("error");
    }
  };

  const getUserProfileData = () => {
    const currentDate = new Date();
    axios
      .get(`${APIClient().API_BASE_URL}/api/getProfileData`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        dispatch(changeFreeTrailStatus(res.data.message.neetSubscription));
        dispatch(changePaymentFrequency(res.data.message.neetSubscription.paymentFrequency));
        const receivedBoard = res.data.message.board;
        const receivedClassName = res.data.message.className;
        const receivedMedium = res.data.message.medium;
        if (receivedBoard === "neet") {
          const receivedEndDate = res.data.message.neetSubscription.endDate;
          const endDate = receivedEndDate === "" ? "" : new Date(receivedEndDate);
          const planName = res.data.message?.neetSubscription?.planName;
          dispatch(changePlanName(planName));
          if (endDate === "") {
            setOpenDialog(false);
          } else if (endDate < currentDate) {
            setOpenDialog(true);
          } else {
            navigate("/home");
          }
        } else if (receivedBoard === "cb") {
          const cbseSubscriptionData = res.data.message?.cbseSubscription?.[0]?.updateSubscriptions;
          const finalClassData = cbseSubscriptionData.filter(
            (item) => item.class_name === receivedClassName && item.medium === receivedMedium,
          )[0];
          const receivedEndDate = finalClassData.endDate;
          const endDate = receivedEndDate === "" ? "" : new Date(receivedEndDate);
          const planName = finalClassData.planName;
          dispatch(changePlanName(planName));
          if (endDate === "") {
            setOpenDialog(false);
          } else if (endDate < currentDate) {
            setOpenDialog(true);
          } else {
            navigate("/home");
          }
        } else {
          const stateBoardSubscriptionData =
            res.data.message?.stateBoardSubscription?.[0]?.updateSubscriptions;
          const finalClassData = stateBoardSubscriptionData.filter(
            (item) => item.class_name === receivedClassName && item.medium === receivedMedium,
          )[0];
          const receivedEndDate = finalClassData.endDate;
          const endDate = receivedEndDate === "" ? "" : new Date(receivedEndDate);
          const planName = finalClassData.planName;
          dispatch(changePlanName(planName));
          if (endDate === "") {
            setOpenDialog(false);
          } else if (endDate < currentDate) {
            setOpenDialog(true);
          } else {
            navigate("/home");
          }
        }
        setLoginData(res.data.message);
        const { mob_no, board, className, email_id, name, userId, medium } = res.data.message;
        dispatch(changeMobileNo(mob_no));
        dispatch(changeBoard(board));
        dispatch(changeClassName(className));
        dispatch(changeEmail(email_id));
        dispatch(changeName(name));
        dispatch(changeUserID(userId));
        dispatch(changeMedium(medium));
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Server Down",
          text: "We are fixing it! Please try again later.",
        }).then(() => {
          navigate("/login");
        });
      });
  };

  const handleclicksubjectname = (subjectname, subjectColor, subjectId) => {
    dispatch(setBookmarkUnitTopics({}));
    if (loginData?.board === "neet") {
      navigate("/neetCategories", {
        state: {
          loginData: loginData,
          subjectname: subjectname,
          subjectColor: subjectColor,
        },
      });
      return;
    } else {
      navigate("/units", {
        state: {
          loginData: loginData,
          subjectname: subjectname,
          subjectColor: subjectColor,
          subjectId: subjectId,
        },
      });
    }
  };

  const onContinuewithbasicBtnClick = () => {
    console.log("newsb");
    console.log(neetThroughBoard, "boards");
    const subscription =
      neetThroughBoard === "neet"
        ? "updateSubscription"
        : neetThroughBoard == "sb"
          ? "updateSbSubscription"
          : "updateCbseSubscription";
    let payload;
    if (neetThroughBoard === "neet") {
      payload = {
        neetSubscription: {
          paymentDetails: {
            transactionId: "",
            amount: 0,
            paymentDate: "",
          },
          planName: "basic",
          paymentFrequency: "",
        },
        userId: userID,
      };
    } else if (neetThroughBoard === "sb" || neetThroughBoard === "cb") {
      payload = {
        userId: userID,
        planName: "basic",
      };
    }
    axios
      .put(`${APIClient().API_BASE_URL}/api/${subscription}`, payload)
      .then((res) => {
        console.log(res, "res");
        getUserProfileData();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }
    getUserProfileData();
  }, []);

  useEffect(() => {
    if (loginData) {
      getSubjectDatas();
    } else {
      setLoading(true);
    }
  }, [loginData]);

  useEffect(() => {
    if (subjectData) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [subjectData]);
  return (
    <>
      {loading ? (
        <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "100vh" }}>
          <img src={gLogo} className='glogo' alt={"logo"}></img>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <SubjectNavbar title={"Home"} />
          <Box sx={{ padding: "40px 30px 0px 30px" }}>
            <Box>
              <Slider {...settings}>
                <div style={{ borderRadius: "10px" }}>
                  <img
                    src={isTab ? tabbanner1 : bannerImage1}
                    alt='banner1'
                    style={{
                      height: "280px",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div style={{ borderRadius: "10px" }}>
                  <img
                    src={isTab ? tabbanner2 : bannerImage2}
                    alt='banner2'
                    style={{
                      height: "280px",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div style={{ borderRadius: "10px" }}>
                  <img
                    src={isTab ? tabbanner3 : bannerImage3}
                    alt='banner3'
                    style={{
                      height: "280px",
                      width: "100%",
                      borderRadius: "20px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div style={{ borderRadius: "10px" }}>
                  <img
                    src={isTab ? tabbanner4 : bannerImage4}
                    alt='banner4'
                    style={{
                      height: "280px",
                      width: "100%",
                      borderRadius: "20px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div style={{ borderRadius: "10px" }}>
                  <img
                    src={isTab ? tabbanner5 : bannerImage5}
                    alt='banner5'
                    style={{
                      height: "280px",
                      width: "100%",
                      borderRadius: "20px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div style={{ borderRadius: "10px" }}>
                  <img
                    src={isTab ? tabbanner6 : bannerImage6}
                    alt='banner6'
                    style={{
                      height: "280px",
                      width: "100%",
                      borderRadius: "20px",
                      objectFit: "contain",
                    }}
                  />
                </div>

                <div style={{ borderRadius: "10px" }}>
                  <img
                    src={isTab ? tabbanner8 : bannerImage8}
                    alt='tabready'
                    style={{
                      height: "280px",
                      width: "100%",
                      borderRadius: "20px",
                      objectFit: "contain",
                    }}
                  />
                </div>

                <div style={{ borderRadius: "10px" }}>
                  <img
                    src={isTab ? tabbanner9 : bannerImage10}
                    alt='banner10'
                    style={{
                      height: "280px",
                      width: "100%",
                      borderRadius: "20px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div style={{ borderRadius: "10px" }}>
                  <img
                    src={isTab ? tabbanner10 : bannerImage11}
                    alt='banner11'
                    style={{
                      height: "280px",
                      width: "100%",
                      borderRadius: "20px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </Slider>
            </Box>

            <div>
              <Container style={{ maxWidth: "1400px" }}>
                <Box my={4}>
                  <HeaderBox
                    bgcolor={Colors.globalColorThemeColor}
                    title={t("Subjects")}
                    backBtn={false}
                    className={loginData.className?.toString()}
                  />
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(1, 1fr)",
                        sm: "repeat(2, 1fr)",
                        md: "repeat(3, 1fr)",
                        lg: "repeat(4, 1fr)",
                      },
                      gap: "3rem",
                      marginTop: "30px",
                    }}
                  >
                    {subjectData.map((items, index) => (
                      <Box
                        key={index}
                        className='cardStyle'
                        sx={{
                          cursor: "pointer",
                          transition: "transform 0.5s ease",
                          border: `1px solid ${getItemColor(items.subjectName)}`,
                          height: "60px",
                          borderRadius: "4px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          ":hover": {
                            transform: "scale(1.1)",
                          },
                        }}
                        onClick={() =>
                          handleclicksubjectname(items.subjectName, items.color, items.subjectId)
                        }
                      >
                        <Box
                          sx={{ display: "flex", height: "100%", alignItems: "center", gap: "5px" }}
                        >
                          <Box
                            sx={{
                              backgroundColor: getItemColor(items.subjectName),

                              width: "10px",
                              height: "100%",
                            }}
                          ></Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: "20px",
                            }}
                          >
                            <img
                              src={items.img}
                              style={{
                                width: "30px",
                                height: "30px",
                              }}
                            />
                            <Typography>{items.subjectName}</Typography>
                          </Box>
                        </Box>
                        <ArrowForwardIosIcon sx={{ color: getItemColor(items.subjectName) }} />
                      </Box>
                    ))}
                  </Box>
                </Box>
                {loginData?.board !== "neet" ? (
                  <>
                    <Box>
                      <Liveclasses loginData={loginData} />
                    </Box>
                  </>
                ) : null}
              </Container>
            </div>
            <TrailExpiry
              openDialog={openDialog}
              onContinuewithbasicBtnClick={onContinuewithbasicBtnClick}
              neetThroughBoard={neetThroughBoard}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default Subjectpage;
