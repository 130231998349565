import React, { useEffect, useState } from "react";
import { Button, Box, Stack, Card, Typography, Divider, IconButton } from "@mui/material";
import Vector from "./images/Vector.png";
import Vector2 from "./images/Vector2.png";
import Vector3 from "./images/Vector3.png";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIClient } from "../../utils/APIClient";
import Colors from "../../utils/Colors";
import { tokenVerify } from "../../utils/Helpers";
import Switch from "@mui/material/Switch";
import { CurrencyRupeeSharp } from "@mui/icons-material";
import Navebar from "../../Navbar/Navebar";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  changeBoard,
  changeMedium,
  changeClass,
  changeName,
  changeMobileNo,
  changeEmailId,
  CurrentName,
  CurrentMobileNo,
  CurrentEmailId,
  CurrentValue,
  CurrentBoard,
  CurrentMedium,
  CurrentClass,
} from "../../redux/registerDataSlice";
import { useTranslation } from "react-i18next";
import "./NeetSubscription.css";

const NeetSubscription = () => {
  const { t } = useTranslation(["translationNeetSubscription"]);
  const dispatch = useDispatch();
  const registerData = useSelector(CurrentValue);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { loginData } = state ? state : false;
  const Contents = [
    t("Pre-Upload Classes"),
    t("Mock exams"),
    t("Previous year solved questions and quizzes"),
    t("Live Classes"),
  ];
  const [selectedPlan, setSelectedPlan] = useState("");
  const [yearly, setYearly] = useState(true);
  const handleSwitchChange = () => {
    setYearly(!yearly);
  };
  const standardPrice = yearly ? "11,000" : "917";
  const premiumPrice = yearly ? "15,000" : "1250";
  const plans = [
    {
      logo: Vector,
      valid: "1 Month",
      price: "₹49.00/mo",
      month: "₹49/month",
    },
    {
      logo: Vector2,
      valid: "3 Month",
      price: "₹119.00/mo",
      month: "₹39.67/month",
    },
    {
      logo: Vector3,
      valid: "1 Year",
      price: "₹449.00/yr",
      month: "₹37.42/month",
    },
  ];
  const features = [
    "Premium Badge",
    "Unlimited Model Questions",
    "Ask Doubts",
    "Unlimited Video Classes",
    "Unlimited Test Practices",
  ];
  const faq = [
    {
      question: "What is GillGall ?",
      answer:
        "Gillgall  is an online learning web & andriod app personalised for CBSE, ICSE & State Board students.",
    },
    {
      question: "How its different from other e-learning services ?",
      answer:
        "We cover a very wide range of syllabus from CBSE Board, ICSE, and State Boards for Tamil Medium and English Medium. ",
    },
    {
      question: "Do you have any discount for organizations ?",
      answer: "Yes, You have contact directly sales person",
    },
    {
      question: "Can I get any free trials ?",
      answer: "Yes, you can access free trial",
    },
    {
      question: "Can I cancel the plan at any time ?",
      answer: "Yes, you can cancel it any time",
    },
    {
      question: "Other queries ?",
      answer: "Open contact us page enter your message",
    },
  ];

  const [open, setOpen] = useState(null);
  const [loading, setLoading] = useState(false);

  const goToOtpPage = (planName, otpResponse) => {
    navigate("/otp", {
      state: {
        planName: planName,
        isNewUser: true,
        otpData: {
          otp: otpResponse.data.OTP,
          sessionId: otpResponse.data.Details,
        },
      },
    });
  };

  const sentOTP = (planName) => {
    const payload = {
      mobNo: "+91" + registerData.mobileNo,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/sentSmsOTP`, payload)
      .then((res) => {
        if (res.data.success) {
          navigate("/otp", {
            state: {
              planName: planName,
              isNewUser: true,
              // otpData: {
              //   otp: otpResponse.data.OTP,
              //   sessionId: otpResponse.data.Details,
              // },
              otpV2Data: {
                otpSessionId: res.data.otpSessionId,
              },
            },
          });
        }
      })
      .catch((err) => {
        console.error(err); // Log error for debugging
        Swal.fire({
          icon: "error",
          confirmButtonColor: Colors.globalColorThemeColor,
          title: "Server Down Try Again Later",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(() => {
          setLoading(false);
        });
      });
  };

  const checkUserIsAlreadyRegistered = async (planName) => {
    setLoading(true);
    const numberCheckDataToSent = {
      name: registerData.name,
      mobNo: "+91" + registerData.mobileNo,
      email_id: registerData.emailID,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/netSmart_number_check`, numberCheckDataToSent)
      .then((res) => {
        if (res.data.message === "User_Registered") {
          Swal.fire({
            title: "This Number is Already Registered",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            dispatch(changeBoard(""));
            dispatch(changeMedium(""));
            dispatch(changeClass(""));
            dispatch(changeName(""));
            dispatch(changeMobileNo(""));
            dispatch(changeEmailId(""));
            navigate("/onboarding");
          });
        } else {
          sentOTP(planName);
          // node_env === "production" ? withOtp() : withOutOtp();
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          confirmButtonColor: Colors.globalColorThemeColor,
          title: "Server Down Try Again Later",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(() => {
          setLoading(false);
        });
      });
  };

  const onPlanBtnClick = (planName) => {
    if (token) {
      const payload = {
        board: "neet",
        neetSubscription: {
          planName: planName,
          paymentFrequency: "",
        },
      };
      axios
        .put(`${APIClient().API_BASE_URL}/api/updateBoardAndClass`, payload, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            navigate("/home");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      checkUserIsAlreadyRegistered(planName);
    }
  };

  return (
    <>
      <Navebar title={t("Plan Selection")} />
      <div>
        <div className='main-subscripe'>
          <Card
            elevation={8}
            sx={{
              height: "520px",
              width: "369px",
              background: "#ffffff",
              borderStartEndRadius: "50px",
              borderBottomLeftRadius: "50px",
              border: "2px solid green",
            }}
          >
            <Typography
              sx={{
                font: "hind",
                fontSize: "25px",
                fontWeight: 600,
                marginTop: "70px",
                marginLeft: "40px",
                color: "green",
              }}
            >
              {t("Basic")}
            </Typography>
            <ul
              style={{
                paddingLeft: "20px",
                marginTop: "70px",
                marginLeft: "20px",
              }}
            >
              {Contents.map((content, index) => (
                <li
                  key={index}
                  style={{
                    listStyle: "none",
                    marginBottom: "25px",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {content === t("Pre-Upload Classes") || content === t("Mock exams") ? (
                    <>
                      <span style={{ marginRight: "5px" }}>{content}</span>
                      <span
                        style={{
                          position: "absolute",
                          fontSize: "12px",
                          color: "#999",
                          marginTop: "50px",
                          marginLeft: "3px",
                        }}
                      >
                        ({t("limited access")})
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ marginRight: "10px", color: "grey" }}>{content}</span>
                    </>
                  )}
                </li>
              ))}
            </ul>
            <Button
              variant='contained'
              sx={{
                height: "50px",
                width: "200px",
                borderEndStartRadius: "17px",
                marginLeft: "90px",
                marginTop: "30px",
                fontSize: "16px",
                fontWeight: 600,
                textTransform: "none",
                background: "green",
              }}
              onClick={() => {
                onPlanBtnClick("basic");
              }}
            >
              {t("Free")}
            </Button>
          </Card>
          <Card
            elevation={8}
            sx={{
              height: "520px",
              width: "369px",
              background: "#ffffff",
              borderStartEndRadius: "50px",
              borderBottomLeftRadius: "50px",
              border: "2px solid #DC6B19",
            }}
          >
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: 600,
                marginTop: "70px",
                marginLeft: "40px",
                color: "#DC6B19",
              }}
            >
              {t("Premium")}
            </Typography>
            <Typography
              sx={{
                fontSize: "35px",
                color: "#000000",
                fontWeight: 900,
                display: "flex",
                alignItems: "center",
                marginLeft: "30px",
              }}
            >
              <CurrencyRupeeSharp sx={{ width: "40px", height: "40px" }} />
              {premiumPrice}
              {yearly ? (
                <>
                  <Typography sx={{ fontSize: "25px", display: "inline" }}>{"/yr"}</Typography>
                  <Typography
                    sx={{
                      color: "#21A6FD",
                      marginLeft: "8px",
                      fontSize: "26px",
                      fontWeight: 600,
                      display: "inline",
                    }}
                  >
                    + GST
                  </Typography>
                </>
              ) : (
                <>
                  <Typography sx={{ fontSize: "25px", display: "inline" }}>{"/mo"}</Typography>
                  <Typography
                    sx={{
                      color: "#21A6FD",
                      marginLeft: "8px",
                      fontSize: "26px",
                      fontWeight: 600,
                      display: "inline",
                    }}
                  >
                    + GST
                  </Typography>
                </>
              )}
            </Typography>
            <ul
              style={{
                paddingLeft: "20px",
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              {Contents.map((content, index) => (
                <li
                  key={index}
                  style={{
                    listStyle: "none",
                    marginBottom: "25px",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: "10px" }}>{content}</span>
                </li>
              ))}
            </ul>
            <Button
              variant='contained'
              onClick={() => {
                onPlanBtnClick("premium");
              }}
              sx={{
                height: "50px",
                borderEndStartRadius: "17px",
                marginLeft: "90px",
                marginTop: "30px",
                fontSize:
                  t("Try 30 Days Free Trial") === "30 நாட்கள் இலவச சோதனையை முயற்சிக்கவும்"
                    ? "13px"
                    : "16px",
                fontWeight: 600,
                textTransform: "none",
                background: "#DC6B19",
                ...(t("Try 30 Days Free Trial") === "30 நாட்கள் இலவச சோதனையை முயற்சிக்கவும்" && {
                  maxWidth: "200px",
                }),
              }}
            >
              {t("Try 30 Days Free Trial")}
            </Button>
          </Card>
        </div>
      </div>
    </>
  );
};

export default NeetSubscription;
