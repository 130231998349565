import { Typography, Box, Toolbar } from "@mui/material";
import elips from "./images/GillgallLogo.png";
import "./navabarstyle.css";
import Colors from "../utils/Colors";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
function Navebar(props) {
  const { t } = useTranslation(["translationNavbar"]);
  return (
    <div>
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            sx={{
              borderBottomRightRadius: "27%",
              borderBottomLeftRadius: "27%",
            }}
          >
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottomRightRadius: "25%",
                borderBottomLeftRadius: "25%",
                height: "100px",
              }}
            >
              <IconButton
                size='large'
                edge='start'
                color='inherit'
                aria-label='menu'
                sx={{ mr: 2 }}
              >
                <img src={elips} className='glogo' alt={"logo"}></img>
              </IconButton>
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginTop: "99px",
                }}
              >
                {/* <Typography variant='h6' component='div' sx={{ textAlign: "center" }}>
                  News
                </Typography> */}
                <Typography size='small' disabled className='onboarding'>
                  {props.title}
                </Typography>
              </Box>
              {/* <Button color='inherit'>GillGall gill</Button> */}
              <Typography variant='h6' sx={{ fontSize: "40px", fontWeight: "bold" }}>
                {t("GillGall")}
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
      </div>
    </div>
  );
}

export default Navebar;
