import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  CardMedia,
  Box,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import LockIcon from "@mui/icons-material/Lock";
const LiveClassList = ({ data }) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const location = useLocation();
  const subjectColor = location?.state?.subjectColor;
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleJoinClick = () => {
    window.open(data.meetLink, "_blank");
  };
  const formattedStartTime = dayjs(data.startTime).format("DD-MM-YYYY hh:mm A");
  const formattedEndTime = dayjs(data.endTime).format("DD-MM-YYYY hh:mm A");
  const unlock = () => {
    navigate("/neetsubscriptionplans");
  };
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        marginTop: "10px",
        height: "180px",
        width: "100%",
        marginBottom: "10px",
        border: "1px solid #ccc",
        boxSizing: "border-box",
        borderRadius: "5px",
      }}
    >
      <CardMedia
        component='img'
        sx={{
          width: "200px",
          height: "100%",
          objectFit: "cover",
          "@media (max-width: 600px)": {
            width: "150px",
          },
        }}
        image={data.topicImg}
        alt='placeholder'
      />
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexDirection: "column",
          flexWrap: "wrap",
          padding: "10px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            component='div'
            gutterBottom
            color={subjectColor}
            sx={{ fontSize: "20px", fontWeight: "bold" }}
          >
            {data.title}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                maxWidth: "65vw",
                fontSize: "15px",
                lineHeight: "1.2",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                opacity: 0.5,
              }}
            >
              {data.desc}
            </Typography>
            {data.desc.length > 375 && (
              <Tooltip title={data.desc} placement='bottom-end'>
                <Typography
                  gutterBottom
                  color={subjectColor}
                  sx={{
                    fontSize: "15px",
                    lineHeight: "1.2",
                    marginBottom: 0,
                    cursor: "pointer",
                    alignSelf: "flex-end",
                    width: "100px",
                  }}
                >
                  Read more
                </Typography>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", opacity: 0.5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              fontWeight: "bold",
            }}
          >
            <Typography sx={{ marginRight: "10px" }}>Author</Typography>
            <Typography sx={{ minWidth: "20vw" }} variant='body3'>
              {data.author}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              fontWeight: "bold",
              marginLeft: "20px",
            }}
          >
            <Typography sx={{ marginRight: "10px" }}>Start Time</Typography>
            <Typography sx={{ minWidth: "10vw" }} variant='body3'>
              {formattedStartTime}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", fontWeight: "bold", marginLeft: "5px" }}
          >
            <Typography sx={{ marginRight: "10px" }}>End Time</Typography>
            <Typography sx={{ minWidth: "10vw" }} variant='body3'>
              {formattedEndTime}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      {data.isLocked === false ? (
        <CardActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "10px",
            paddingRight: "30px",
            marginLeft: "auto",
            "@media (max-width: 1024px)": {
              marginTop: "10px",
              justifyContent: "center",
            },
          }}
        >
          <Button
            variant='contained'
            size='small'
            onClick={handleJoinClick}
            sx={{ width: "100%", marginLeft: "8px", backgroundColor: subjectColor }}
          >
            Join
          </Button>
        </CardActions>
      ) : (
        <CardActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "10px",
            paddingRight: "30px",
            marginLeft: "auto",
            "@media (max-width: 1024px)": {
              marginTop: "10px",
              justifyContent: "center",
            },
          }}
        >
          <LockIcon />
          <Button
            variant='contained'
            size='small'
            onClick={unlock}
            sx={{ width: "100%", marginLeft: "8px", backgroundColor: "#DAA520" }}
          >
            Unlock
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default LiveClassList;
