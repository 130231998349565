import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navebar from "../../Navbar/Navebar";
import studentimg from "./images/registerlogo.png";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, TextField } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import MailIcon from "@mui/icons-material/Mail";
import "./registerstyle.css";
import {
  changeBoard,
  changeMedium,
  changeClass,
  changeName,
  changeMobileNo,
  changeEmailId,
  CurrentName,
  CurrentMobileNo,
  CurrentEmailId,
  CurrentValue,
  CurrentBoard,
  CurrentMedium,
  CurrentClass,
} from "../../redux/registerDataSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { APIClient } from "../../utils/APIClient";
import Swal from "sweetalert2";
import "animate.css";
import Colors from "../../utils/Colors";
import { useTranslation } from "react-i18next";
const Register = () => {
  const { t } = useTranslation(["translationRegister"]);
  const navigate = useNavigate();
  // const node_env = process.env.REACT_APP_NODE_ENV;
  const name = useSelector(CurrentName);
  const mobileNo = useSelector(CurrentMobileNo);
  const emailId = useSelector(CurrentEmailId);
  const registerData = useSelector(CurrentValue);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isFreeTrail, setIsFreeTrail] = useState(false);
  const [isFreeTrailUsed, setIsFreeTrailUsed] = useState(false);
  const [planName, setPlanName] = useState(false);
  const getBoard = useSelector(CurrentBoard);
  const getMedium = useSelector(CurrentMedium);
  const getClass = useSelector(CurrentClass);

  const [textFieldValidation, setTextFieldValidation] = useState({
    name: { error: "", isValid: false },
    mobno: { error: "", isValid: false },
    emailid: { error: "", isValid: false },
  });
  const handleChangeName = (val) => {
    setTextFieldValidation((preState) => ({
      ...preState,
      name: { error: "", isValid: false },
    }));
    dispatch(changeName(val));
  };

  const handleChangeMobNo = (val) => {
    setTextFieldValidation((preState) => ({
      ...preState,
      mobno: { error: "", isValid: false },
    }));
    dispatch(changeMobileNo(val));
  };

  const handleChangeEmailId = (val) => {
    setTextFieldValidation((preState) => ({
      ...preState,
      emailid: { error: "", isValid: false },
    }));
    dispatch(changeEmailId(val));
  };

  /* eslint-disable */
  var regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const sentOTP = (planName) => {
    const payload = {
      mobNo: "+91" + registerData.mobileNo,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/sentSmsOTP`, payload)
      .then((res) => {
        if (res.data.success) {
          navigate("/otp", {
            state: {
              planName: planName,
              isNewUser: true,
              otpV2Data: {
                otpSessionId: res.data.otpSessionId,
              },
            },
          });
        }
      })
      .catch((err) => {
        console.error(err); // Log error for debugging
        Swal.fire({
          icon: "error",
          confirmButtonColor: Colors.globalColorThemeColor,
          title: "Server Down Try Again Later",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(() => {
          setLoading(false);
        });
      });
  };

  const goToTheSubscription = () => {
    if (name === "") {
      setTextFieldValidation((preState) => ({
        ...preState,
        name: { error: t("Please Enter Your Name"), isValid: true },
      }));
      return false;
    } else if (mobileNo === "" || mobileNo.length !== 10) {
      setTextFieldValidation((preState) => ({
        ...preState,
        mobno: { error: t("Please Enter Your MobileNo"), isValid: true },
      }));
      return false;
    } else if (emailId === "" || !regEmail.test(emailId)) {
      setTextFieldValidation((preState) => ({
        ...preState,
        emailid: {
          error: emailId === "" ? t("Please Enter Your Email") : t("Please Enter Valid Email"),
          isValid: true,
        },
      }));
      return false;
    } else {
      if (registerData.board === "neet") {
        navigate("/neetsubscribe");
      } else {
        // sentOTP("basic");
        setLoading(true);
        const numberCheckDataToSent = {
          name: registerData.name,
          mobNo: "+91" + registerData.mobileNo,
          email_id: registerData.emailID,
        };
        axios
          .post(`${APIClient().API_BASE_URL}/api/netSmart_number_check`, numberCheckDataToSent)
          .then((res) => {
            if (res.data.message === "User_Registered") {
              Swal.fire({
                title: "This Number is Already Registered",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              }).then(() => {
                dispatch(changeBoard(""));
                dispatch(changeMedium(""));
                dispatch(changeClass(""));
                dispatch(changeName(""));
                dispatch(changeMobileNo(""));
                dispatch(changeEmailId(""));
                navigate("/onboarding");
              });
            } else {
              sentOTP("basic");
              // node_env === "production" ? withOtp() : withOutOtp();
            }
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              confirmButtonColor: Colors.globalColorThemeColor,
              title: "Server Down Try Again Later",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then(() => {
              setLoading(false);
            });
          });
      }
    }
  };
  const handleSubmit = async () => {
    if (name === "") {
      setTextFieldValidation((preState) => ({
        ...preState,
        name: { error: "Please Enter Your Name", isValid: true },
      }));
    } else if (mobileNo === "" || mobileNo.length !== 10) {
      setTextFieldValidation((preState) => ({
        ...preState,
        mobno: { error: "Please Enter Your MobileNo", isValid: true },
      }));
    } else if (emailId === "" || !regEmail.test(emailId)) {
      setTextFieldValidation((preState) => ({
        ...preState,
        emailid: {
          error: emailId === "" ? "Please Enter Your Email" : "Please Enter Valid Email",
          isValid: true,
        },
      }));
    } else {
      setLoading(true);
      const numberCheckDataToSent = {
        name: registerData.name,
        mobNo: "+91" + registerData.mobileNo,
        email_id: registerData.emailID,
      };
      axios
        .post(`${APIClient().API_BASE_URL}/api/netSmart_number_check`, numberCheckDataToSent)
        .then((res) => {
          if (res.data.message === "User_Registered") {
            Swal.fire({
              title: "This Number is Already Registered",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then(() => {
              dispatch(changeBoard(""));
              dispatch(changeMedium(""));
              dispatch(changeClass(""));
              dispatch(changeName(""));
              dispatch(changeMobileNo(""));
              dispatch(changeEmailId(""));
              navigate("/onboarding");
            });
          } else {
            sentOTP("basic");
            // node_env === "production" ? withOtp() : withOutOtp();
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: Colors.globalColorThemeColor,
            title: "Server Down Try Again Later",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            setLoading(false);
          });
        });
    }
  };

  useEffect(() => {
    dispatch(changeName(""));
    dispatch(changeMobileNo(""));
    dispatch(changeEmailId(""));
    dispatch(changeBoard(getBoard));
    dispatch(changeMedium(getMedium));
    dispatch(changeClass(getClass));
  }, []);

  return (
    <div>
      <Navebar title={t("Register")} />
      <div className='creatingdiv'>
        <h1 className='creatacc'>{t("Create An Account")}</h1>
        <div>
          <img src={studentimg} alt={"characterimage"} className='stdimages' />
        </div>
        <div className='registertextfield'>
          <TextField
            autoFocus
            // onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
            value={name}
            helperText={textFieldValidation.name.error}
            error={textFieldValidation.name.isValid}
            onChange={(e) => handleChangeName(e.target.value)}
            className='threetextfields'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <AccountCircle sx={{ mr: 1 }} />
                </InputAdornment>
              ),
            }}
            placeholder={t("Enter your Name")}
            id='fullWidth'
            sx={{
              "& .MuiFormHelperText-root": {
                position: "absolute",
                bottom: -25,
                margin: 0,
                fontSize: "12px",
              },
              height: "60px",
              position: "relative",
            }}
          />

          <TextField
            // onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
            type={"number"}
            value={mobileNo}
            helperText={textFieldValidation.mobno.error}
            error={textFieldValidation.mobno.isValid}
            onChange={(e) => handleChangeMobNo(e.target.value)}
            className='threetextfields'
            // sx={{
            //   "& .MuiOutlinedInput-input": {
            //     "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            //       "-webkit-appearance": "none",
            //     },
            //   },
            // }}
            sx={{
              "& .MuiFormHelperText-root": {
                position: "absolute",
                bottom: -25,
                margin: 0,
                fontSize: "12px",
              },
              height: "60px",
              position: "relative",
            }}
            onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <LocalPhoneIcon sx={{ mr: 1 }} />
                </InputAdornment>
              ),
            }}
            placeholder={t("Enter your Mobile Number")}
            id='fullWidth'
          />

          <TextField
            // onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
            value={emailId}
            helperText={textFieldValidation.emailid.error}
            error={textFieldValidation.emailid.isValid}
            onChange={(e) => handleChangeEmailId(e.target.value)}
            className='threetextfields'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <MailIcon sx={{ mr: 1 }} />
                </InputAdornment>
              ),
            }}
            placeholder={t("Enter your Email Id")}
            id='fullWidth'
            sx={{
              "& .MuiFormHelperText-root": {
                position: "absolute",
                bottom: -25,
                margin: 0,
                fontSize: "12px",
              },
              height: "60px",
              position: "relative",
            }}
          />
        </div>
      </div>

      <div className='creativebutton'>
        <Button
          disabled={loading}
          // onClick={handleSubmit}
          onClick={goToTheSubscription}
          // onClick={()=>navigate('/subscribe')}
          variant='contained'
          className='letstartbtn'
          sx={{
            backgroundColor: Colors.globalColorThemeColor,
            "&:hover": { backgroundColor: Colors.globalColorThemeColor },
          }}
        >
          {loading ? t("LET'S START LEARNING...") : t("LET'S START LEARNING")}
        </Button>
      </div>
    </div>
  );
};

export default Register;
