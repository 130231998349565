import { Stack, Box, Typography, Button, Card } from "@mui/material";
import React, { useState, useEffect } from "react";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import BookmarkIcon from "./images/bookmarkIcon.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import { useLocation, useNavigate } from "react-router-dom";
import { setBookmarkUnitTopics } from "../../redux/unitTopicsSlice";
import axios from "axios";
import { APIClient } from "../../utils/APIClient";
import { useDispatch } from "react-redux";
import { tokenVerify } from "../../utils/Helpers";

function Bookmarks() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state;
  const { medium, mob_no, className } = state ? state.loginData : false;

  const bookmarkMedium = medium === "tm" ? "tamMedBookmarksData" : "engMedBookmarksData";

  const [getData, setGetData] = useState();

  const bookmarkUnitTopicsFn = (subName, items) => {
    dispatch(setBookmarkUnitTopics(items));
    navigate("/units", {
      state: {
        loginData: state.loginData,
        subjectname: subName,
      },
    });
  };

  const removeBookmarksData = (subName, unitSelectedIndex, unitTopicIndex) => {
    try {
      let localBookmarkData = getData;
      const removedData = localBookmarkData[bookmarkMedium][className][subName].filter(
        (e) => !(e.unitSelectedIndex === unitSelectedIndex && e.unitTopicIndex === unitTopicIndex),
      );
      let RemovedlocalData = {
        ...localBookmarkData,
        [bookmarkMedium]: {
          ...localBookmarkData[bookmarkMedium],
          [className]: {
            ...localBookmarkData[bookmarkMedium][className],
            [subName]: removedData,
          },
        },
      };
      let temp = RemovedlocalData[bookmarkMedium][className];

      const filteredObj = Object.fromEntries(
        Object.entries(temp).filter(([key, value]) => Array.isArray(value) && value.length > 0),
      );
      RemovedlocalData[bookmarkMedium][className] = filteredObj;

      if (Object.keys(RemovedlocalData[bookmarkMedium][className]).length === 0) {
        delete RemovedlocalData[bookmarkMedium][className];
        if (Object.keys(RemovedlocalData[bookmarkMedium]).length === 0) {
          delete RemovedlocalData[bookmarkMedium];
          if (Object.keys(RemovedlocalData).length > 1) {
            setGetData(RemovedlocalData);
            axios.post(`${APIClient().API_BASE_URL}/api/updatebookmarksdata`, RemovedlocalData);
          } else {
            setGetData();
            axios.post(`${APIClient().API_BASE_URL}/api/updatebookmarksdata`, RemovedlocalData);
          }
        } else {
          setGetData(RemovedlocalData);
          axios.post(`${APIClient().API_BASE_URL}/api/updatebookmarksdata`, RemovedlocalData);
        }
      } else {
        setGetData(RemovedlocalData);
        axios.post(`${APIClient().API_BASE_URL}/api/updatebookmarksdata`, RemovedlocalData);
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    const isValidToken = tokenVerify();
    if (!state || !isValidToken) {
      navigate("/bookmarks");
      return;
    }
    const dataToSent = { mob_no };
    axios.post(`${APIClient().API_BASE_URL}/api/bookmarksdata`, dataToSent).then((res) => {
      setGetData(res.data.responseData[0]);
    });
  }, []);

  return (
    <>
      <Box>
        <SubjectNavbar title={"Bookmarks"} />
        {getData ? (
          Object.keys(getData).includes(bookmarkMedium) &&
          Object.keys(getData[bookmarkMedium]).includes(className) ? (
            Object.keys(getData[bookmarkMedium][className]).map((subName, index) => (
              <Stack key={index} mt={5} alignItems={"center"}>
                <Button variant='outlined'>{subName}</Button>
                <Stack
                  mt={3}
                  sx={{
                    width: {
                      xs: "98%",
                      sm: "90%",
                      md: "70%",
                      lg: "50%",
                      xl: "40%",
                    },
                  }}
                >
                  {getData[bookmarkMedium][className][subName].map((items, subindex) => (
                    <Card key={subindex} sx={{ borderRadius: "8px", cursor: "pointer", mb: 2 }}>
                      <Stack direction={{ sm: "row" }} justifyContent={"space-between"} gap={1}>
                        <Box onClick={() => bookmarkUnitTopicsFn(subName, items)}>
                          <Stack
                            direction={"row"}
                            gap={1}
                            sx={{
                              bgcolor: "#287F87",
                              color: "white",
                              fontWeight: "bold",
                              padding: "5px 10px",
                              height: "25%",
                              borderBottomRightRadius: "15px",
                            }}
                          >
                            <Typography>Unit</Typography>
                            <Typography>{items.unitSelectedIndex + 1}</Typography>
                          </Stack>
                        </Box>
                        <Stack
                          onClick={() => bookmarkUnitTopicsFn(subName, items)}
                          sx={{ width: "100%" }}
                        >
                          <Typography mt={2} sx={{ fontWeight: "bold", textAlign: "center" }}>
                            {items.unitName}
                          </Typography>
                          <Typography
                            mt={1}
                            mb={1}
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {items.unitTopicName}
                          </Typography>
                        </Stack>
                        <Stack alignItems={"center"} justifyContent={"center"} mr={1}>
                          <Button
                            onClick={() =>
                              removeBookmarksData(
                                subName,
                                items.unitSelectedIndex,
                                items.unitTopicIndex,
                              )
                            }
                            variant='contained'
                            sx={{
                              borderRadius: "10px",
                              bgcolor: "#FF0000",
                              ":hover": { bgcolor: "#FF0000" },
                            }}
                          >
                            Remove <BookmarkRemoveIcon />
                          </Button>
                        </Stack>
                      </Stack>
                    </Card>
                  ))}
                </Stack>
              </Stack>
            ))
          ) : (
            <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "80vh" }}>
              <LazyLoadImage
                effect='blur'
                src={BookmarkIcon}
                alt={"Bookmarks"}
                style={{ width: "191px", height: "250px" }}
              />
              <Typography variant='h4' sx={{ mt: 5 }}>
                No Bookmarks Added
              </Typography>
            </Stack>
          )
        ) : (
          <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "80vh" }}>
            <LazyLoadImage
              effect='blur'
              src={BookmarkIcon}
              alt={"Bookmarks"}
              style={{ width: "191px", height: "250px" }}
            />
            <Typography variant='h4' sx={{ mt: 5 }}>
              No Bookmarks Added
            </Typography>
          </Stack>
        )}
      </Box>
    </>
  );
}

export default Bookmarks;
