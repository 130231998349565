import { Avatar, Button } from "@mui/material";
import React, { useState } from "react";
import cup from "../../Units/Image/twemoji_trophy.svg";
import EasyMediumHard from "./EasyMediumHard";
import UnitsQuiz from "./UnitsQuiz";
import Colors from "../../../utils/Colors";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Result({ totalQuestions, correctAnswers, duration, mcqDataforQuiz, mcqList }) {
  const navigate = useNavigate();
  const { t } = useTranslation(["translationResult"]);
  const [showDiv1, setShowDiv1] = useState(true);
  const [showDiv2, setShowDiv2] = useState(false);
  const [unitsQuize2, setUnitsQuize2] = useState(false);
  const wrongAnswers = totalQuestions - correctAnswers;
  const accuracy = ((correctAnswers / totalQuestions) * 100).toFixed(2);
  const smalldivdata = [
    { name: "Correct", number: correctAnswers },
    { name: "Wrong", number: wrongAnswers },
    { name: "Accuracy", number: `${accuracy}%` },
    // { name: "Duration", number: duration ? `${duration} seconds` : "60 seconds" },
  ];

  const handleRetest = () => {
    setShowDiv1(false);
    setShowDiv2(true);
  };

  const handleCloseTest = () => {
    // setUnitsQuize2(true);
    setShowDiv1(false);
    navigate(-1);
  };

  return (
    <>
      {showDiv1 && (
        <div className='mainholediv'>
          <div className='scorediv' style={{ "--bgcolor": Colors.globalColorThemeColor }}>
            <div className='scorehead' style={{ "--bgcolor": Colors.globalColorThemeColor }}>
              <h3 className='scorename'>SCORE</h3>
            </div>
            <div className='scoreandcupdiv'>
              <img className='cups' src={cup} alt={"cups"} />
              <Avatar
                sx={{
                  backgroundColor: Colors.globalColorThemeColor,
                  p: 2,
                  width: "80px",
                  height: "80px",
                }}
              >
                <p>
                  {correctAnswers}/{totalQuestions}
                </p>
              </Avatar>
              <img className='cups' src={cup} alt={"two cups"} />
            </div>
          </div>
          <div className='fourdivcontain'>
            {smalldivdata.map((val, index) => (
              <div
                key={index}
                className='smalldiv'
                style={{ "--bgcolor": Colors.globalColorThemeColor }}
              >
                <div className='firstdivavt' style={{ "--bgcolor": Colors.globalColorThemeColor }}>
                  <h4 className='scorename'>{val.name}</h4>
                </div>
                <div className='smalldivavtr'>
                  <Avatar
                    sx={{
                      backgroundColor: Colors.globalColorThemeColor,
                      p: 1,
                      width: "50px",
                      height: "50px",
                    }}
                  >
                    <p className='sizeForDuration'>{val.number}</p>
                  </Avatar>
                </div>
              </div>
            ))}
          </div>
          <div className='buttondiv'>
            <Button variant='outlined' onClick={handleRetest}>
              {t("Retest")}
            </Button>
            <Button variant='contained' onClick={handleCloseTest}>
              {t("Close Test")}
            </Button>
          </div>
        </div>
      )}
      {showDiv2 && <EasyMediumHard mcqDataforQuiz={mcqDataforQuiz} />}
      {unitsQuize2 && <UnitsQuiz mcqList={mcqList} />}
    </>
  );
}

export default Result;
