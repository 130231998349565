import React, { useEffect, useState } from "react";
import HeaderBox from "../../../common/components/HeaderBox";
import axios from "axios";
import { APIClient } from "../../../utils/APIClient";
import Colors from "../../../utils/Colors";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  colors,
  Dialog,
  DialogContent,
  Tooltip,
  Typography,
} from "@mui/material";
import "./../style.css";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
const Liveclasses = ({ loginData }) => {
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [sbLiveClass, setSbLiveClass] = useState();
  const token = localStorage.getItem("token");
  const { t } = useTranslation(["translationLiveclasses"]);
  const navigate = useNavigate();
  useEffect(() => {
    const payload = {
      page: 1,
      itemsPerPage: 5,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/getAllLiveClassForUser`, payload, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setSbLiveClass(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching live classes:", error);
      });
  }, []);
  const handleJoinClick = (meetLink) => {
    window.open(meetLink, "_blank");
  };
  const unlock = () => {
    navigate("/stateboardsubscription");
  };
  return (
    <>
      <div>
        <HeaderBox
          bgcolor={Colors.globalColorThemeColor}
          title={t("Live Class")}
          className={loginData.className}
        />
        {sbLiveClass && sbLiveClass.length > 0 ? (
          sbLiveClass.map((items, index) => (
            <Card
              key={index}
              // sx={{
              //   display: "flex",
              //   flexDirection: "row",
              //   marginTop: "10px",
              //   height: "180px",
              //   width: "100%",
              //   marginBottom: "10px",
              //   border: "1px solid #ccc",
              //   boxSizing: "border-box",
              //   borderRadius: "5px",
              // }}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens
                marginTop: "10px",
                height: { xs: "auto", sm: "" }, // Auto height for small screens
                width: "100%",
                marginBottom: "10px",
                border: "1px solid #ccc",
                boxSizing: "border-box",
                borderRadius: "5px",
              }}
            >
              <CardMedia
                component='img'
                // sx={{
                //   width: "200px",
                //   height: "100%",
                //   objectFit: "cover",
                //   "@media (max-width: 600px)": {
                //     width: "150px",
                //   },
                // }}
                sx={{
                  width: { xs: "100%", sm: "120px" }, // Full width on small screens  xs,sm,md,lg,xl
                  height: { xs: "auto", sm: "auto" }, // Auto height for small screens
                  objectFit: "cover",
                  // alignItems: "center",
                  // display: "flex",
                  // justifyContent: "center",
                }}
                image={items.topicImg}
                alt='placeholder'
              />
              <CardContent
                // sx={{
                //   display: "flex",
                //   justifyContent: "space-between",
                //   alignItems: "flex-start",
                //   flexDirection: "column",
                //   flexWrap: "wrap",
                //   padding: "10px",
                //   backgroundColor: { xs: "green", sm: "blue" },
                // }}

                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "column" }, // Stack content vertically on small screens
                  justifyContent: "space-between",
                  alignItems: { xs: "center", sm: "flex-start" }, // Center-align text on small screens
                  padding: "10px",
                  width: {
                    xs: "100%",
                    sm: "90%",
                    md: "100%",
                    lg: "100%",
                    xl: "100%",
                  },
                }}
              >
                <Box
                  // sx={{
                  //   width: "100%",
                  // }}
                  sx={{
                    width: "100%",
                    marginBottom: { xs: "10px", sm: "0" }, // Add spacing on small screens
                  }}
                >
                  <Box
                    // sx={{
                    //   display: "flex",
                    //   flexDirection: "row",
                    //   opacity: 0.5,
                    // }}
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens
                      alignItems: { xs: "center", sm: "flex-start" },
                      opacity: 0.5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        fontWeight: "bold",
                      }}
                    >
                      <Typography
                        sx={{
                          marginRight: "10px",
                          fontSize: {
                            // xs: "blue",
                            sm: "12px",
                            md: "15px",
                            lg: "15px",
                            xl: "15px",
                          },
                        }}
                      >
                        Title
                      </Typography>
                      <Typography
                        sx={{
                          minWidth: "20vw",
                          fontSize: {
                            // xs: "blue",
                            sm: "12px",
                            md: "15px",
                            lg: "15px",
                            xl: "15px",
                          },
                        }}
                        variant='body3'
                      >
                        {items.title}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        fontWeight: "bold",

                        marginLeft: {
                          // xs: "blue",
                          sm: "0px",
                          md: "20px",
                          lg: "20px",
                          xl: "20px",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          marginRight: "10px",
                          fontSize: {
                            // xs: "blue",
                            sm: "12px",
                            md: "15px",
                            lg: "15px",
                            xl: "15px",
                          },
                        }}
                      >
                        Subject
                      </Typography>
                      <Typography
                        sx={{
                          minWidth: "10vw",
                          fontSize: {
                            // xs: "blue",
                            sm: "12px",
                            md: "15px",
                            lg: "15px",
                            xl: "15px",
                          },
                        }}
                        variant='body3'
                      >
                        {items.subject}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        maxWidth: "65vw",

                        fontSize: {
                          // xs: "blue",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        lineHeight: "1.2",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        opacity: 0.5,
                        display: "flex",
                        flexWrap: "wrap",
                        wordBreak: "break-word", // Ensures long words break and wrap within the container
                        whiteSpace: "normal", // Allows the text to wrap normally
                      }}
                    >
                      {items.desc.length > 100 ? `${items.desc.slice(0, 100)}...` : items.desc}
                    </Typography>
                    {items.desc.length > 100 && (
                      <Tooltip title={items.desc} placement='bottom-end'>
                        <Typography
                          gutterBottom
                          color={Colors.globalColorThemeColor}
                          sx={{
                            fontSize: "15px",
                            lineHeight: "1.2",
                            marginBottom: 0,
                            cursor: "pointer",
                            alignSelf: "flex-start",
                            width: "100px",
                            textAlign: "right",
                          }}
                        >
                          Read more
                        </Typography>
                      </Tooltip>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "row", sm: "row" },
                    opacity: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography
                      sx={{
                        marginRight: "10px",
                        fontSize: {
                          // xs: "blue",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      Author
                    </Typography>
                    <Typography
                      sx={{
                        minWidth: "20vw",
                        fontSize: {
                          // xs: "blue",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                      variant='body3'
                    >
                      {items.author}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      fontWeight: "bold",
                      marginLeft: {
                        // xs: "blue",
                        sm: "0px",
                        md: "20px",
                        lg: "20px",
                        xl: "20px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        // marginRight: "10px",
                        fontSize: {
                          // xs: "blue",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      Start Time
                    </Typography>
                    <Typography
                      sx={{
                        minWidth: "10vw",
                        fontSize: {
                          // xs: "blue",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                      variant='body3'
                    >
                      {dayjs(items.startTime).format("DD/MM/YYYY hh:mm A")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      fontWeight: "bold",
                      marginLeft: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        marginRight: "10px",
                        fontSize: {
                          // xs: "blue",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      End Time
                    </Typography>
                    <Typography
                      sx={{
                        minWidth: "10vw",
                        fontSize: {
                          // xs: "blue",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                      variant='body3'
                    >
                      {dayjs(items.endTime).format("DD/MM/YYYY hh:mm A")}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
              {items.isLocked === false ? (
                <CardActions
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "10px",
                    marginLeft: "auto",
                    "@media (max-width: 1024px)": {
                      marginTop: "10px",
                      justifyContent: "center",
                    },
                  }}
                >
                  <Button
                    variant='contained'
                    size='small'
                    onClick={() => handleJoinClick(items.meetLink)}
                    // sx={{
                    //   width: "100%",
                    //   marginLeft: "8px",
                    // }}
                    sx={{
                      width: { xs: "100%", sm: "auto" },
                      "@media (max-width: 1024px)": {
                        fontSize: "10px",
                      },
                    }}
                  >
                    {t("Join")}
                  </Button>
                </CardActions>
              ) : (
                <CardActions
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    // gap: "10px",
                    "@media (max-width: 1024px)": {
                      //marginTop: "10px",
                    },
                  }}
                >
                  <Box
                    width={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    {/* <LockIcon
                      sx={{
                        "@media (max-width: 1024px)": {
                          fontSize: "22px",
                        },
                      }}
                    /> */}
                    <Button
                      variant='contained'
                      size='small'
                      onClick={unlock}
                      sx={{
                        // width: "100%",
                        // marginLeft: "8px",
                        backgroundColor: "#DAA520",
                        // width: { xs: "100%", sm: "auto" },
                        // backgroundColor: colors.primary,
                        "@media (max-width: 1024px)": {
                          fontSize: "9px",
                        },
                      }}
                      startIcon={<LockIcon />}
                    >
                      {t("Unlock")}
                    </Button>
                  </Box>
                </CardActions>
              )}
            </Card>
          ))
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p style={{ fontWeight: "bold" }}>{t("No live classes available.")}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Liveclasses;
