import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { APIClient } from "../../utils/APIClient";
import {
  AppBar,
  Box,
  Button,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Skeleton,
  Avatar,
  Divider,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import "./SubNavbarStyle.css";
import axios from "axios";
import HomeIcon from "./images/homeIcon.svg";
import BookmarkIcon from "./images/bookmarkIcon.svg";
import PremiumIcon from "./images/premiumIcon.svg";
import AppFeatureIcon from "./images/appFeatureIcon.svg";
import TermsAndConditionIcon from "./images/T&CIcon.svg";
import AboutUsIcon from "./images/aboutUsIcon.svg";
import ContactUsIcon from "./images/contactUsIcon.svg";
import SignOutIcon from "./images/signoutIcon.svg";
import { useNavigate } from "react-router-dom";
import Termsandcondition from "../../common/Termsandcondition";
import Colors from "../../utils/Colors";
import jwtDecode from "jwt-decode";
import { ArrowDownward, ArrowDropDown } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useTransition } from "react";
import {
  CurrentMobileNo,
  CurrentBoard,
  CurrentClassName,
  CurrentName,
  CurrentEmailId,
  CurrentFrequency,
  CurrentPlanName,
  CurrentMedium,
  CurrentFreeTrailStatus,
} from "../../redux/profileDataSlice";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PrevYearQuestionsPage from "../PrevYearQuestionsPage/PrevYearQuestionsPage";
import NeetSubscriptionPlans from "../Subscription/components/NeetSubscriptionPlans/NeetSubscriptionPlans";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const SubjectNavbar = (props) => {
  const { t, i18n } = useTranslation(["translationSubjectNavbar"]);
  const planName = useSelector(CurrentPlanName);
  const mobileNo = useSelector(CurrentMobileNo);
  const className = useSelector(CurrentClassName);
  const board = useSelector(CurrentBoard);
  const hideChangeboard = location?.state?.hideChangeboard;
  const name = useSelector(CurrentName);
  const medium = useSelector(CurrentMedium);
  const emailId = useSelector(CurrentEmailId);

  const paymentFrequencys = useSelector(CurrentFrequency);
  const drawerWidth = 260;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openTermsandConditions, setOpenTermsandConditions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en",
  );
  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleLanguageMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setAnchorEl(null);
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
    setCurrentLanguage(lng);
    handleLanguageMenuClose();
  };

  const getLanguageLabel = (lng) => {
    switch (lng) {
      case "en":
        return "EN";
      case "ta":
        return "TA";
      default:
        return "EN";
    }
  };
  const AppBarData = [
    {
      text: t("HOME"),
      icon: HomeIcon,
      reDirect: () => navigate("/home"),
    },
    {
      text: t("BOOKMARKS"),
      icon: BookmarkIcon,
      reDirect: () => navigate("/bookmarks"),
    },
    {
      text: t("PREMIUM"),
      icon: PremiumIcon,
      reDirect: () => {
        if (board === "neet") {
          navigate("/neetsubscriptionplans");
        } else {
          navigate("/stateboardsubscription");
        }
      },
    },
    {
      text: t("APP FEATURES"),
      icon: AppFeatureIcon,
      reDirect: () => navigate("/appfeature"),
    },
    {
      text: t("TERMS AND CONDITIONS"),
      icon: TermsAndConditionIcon,
      reDirect: () => setOpenTermsandConditions(true),
    },
    {
      text: t("ABOUT US"),
      icon: AboutUsIcon,
      reDirect: () => navigate("/aboutus"),
    },
    {
      text: t("CONTACT US"),
      icon: ContactUsIcon,
      reDirect: () => navigate("/contactus"),
    },
  ];

  const onUpgradeBtnClick = () => {
    if (board === "neet") {
      navigate("/neetsubscriptionplans");
    } else if (board === "cb") {
      navigate("/cbsesubscriptions");
    } else {
      navigate("/stateboardsubscription");
    }
  };

  const handleClickSelectBoard = () => {
    if (board === "neet") {
      navigate("/selectboard", {
        state: {
          ChangeToStateBoard: true,
        },
      });
    } else {
      navigate("/selectboard", {
        state: {
          ChangeToNeet: true,
        },
      });
    }
  };

  const signOutFn = () => {
    navigate("/onboarding");
    localStorage.removeItem("token");
  };

  const handleScroll = () => {
    setOpen(false);
  };

  window.addEventListener("scroll", handleScroll);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 30px",
        }}
      >
        <Box display={"flex"} alignItems={"center"}>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon fontSize='large' sx={{ color: Colors.globalColorThemeColor }} />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <MenuIcon fontSize='large' sx={{ color: Colors.globalColorThemeColor }} />
            </IconButton>
          )}
          <Stack ml={3}>
            <Typography
              sx={{
                color: Colors.globalColorThemeColor,
                fontWeight: "900",
                fontSize: { xs: "16px", md: "27px" },
                textWrap: "nowrap",
              }}
            >
              {board === "neet"
                ? t("GillGall NEET")
                : board === "sb"
                  ? t("GillGall State Board")
                  : board === "cb"
                    ? t("GillGall CBSE")
                    : t("GillGall")}
            </Typography>
            <Box
              sx={{
                border: `1px solid ${Colors.globalColorThemeColor}`,
                borderRadius: "20px",
                textAlign: "center",
                background: "#e6f5ff",
                color: "#DAA520",
                fontWeight: "600",
                fontSize: { xs: "12px", md: "16px" },
              }}
            >
              {planName?.toUpperCase() || ""}
            </Box>
          </Stack>
        </Box>

        <Box display={"flex"} alignItems={"center"} sx={{ justifyContent: "flex-end" }}>
          {!isMobile && (
            <Box>
              {board === "neet" && (planName !== "premium" || paymentFrequencys === "") && (
                <Button
                  variant='contained'
                  size='small'
                  onClick={onUpgradeBtnClick}
                  // className='upgrade-btn'
                  sx={{
                    // width: { xs: "100px", md: "150px" },
                    fontSize: { xs: "8px", md: "12px" },
                    textTransform: "capitalize",
                    margin: "5px",
                    backgroundColor: "#DAA520",
                    "&:hover": {
                      backgroundColor: "#DAA520",
                    },
                  }}
                >
                  {t("Upgrade Plan")}
                </Button>
              )}

              {board === "sb" && planName !== "premium" && (
                <Button
                  variant='contained'
                  size='small'
                  onClick={onUpgradeBtnClick}
                  // className='upgrade-btn'
                  sx={{
                    // width: { xs: "80px", md: "80px" },
                    fontSize: { xs: "9px", md: "12px" },
                    textTransform: "capitalize",
                    margin: "5px",
                    backgroundColor: "#DAA520",
                    "&:hover": {
                      backgroundColor: "#DAA520",
                    },
                  }}
                >
                  {t("Upgrade Plan")}
                </Button>
              )}
              {board === "cb" && planName !== "premium" && (
                <Button
                  variant='contained'
                  size='small'
                  onClick={onUpgradeBtnClick}
                  // className='upgrade-btn'
                  sx={{
                    // width: { xs: "80px", md: "80px" },
                    fontSize: { xs: "9px", md: "12px" },
                    textTransform: "capitalize",
                    margin: "5px",
                    backgroundColor: "#DAA520",
                    "&:hover": {
                      backgroundColor: "#DAA520",
                    },
                  }}
                >
                  {t("Upgrade Plan")}
                </Button>
              )}
            </Box>
          )}
          {!isMobile && (
            <Box display={"flex"} alignItems={"center"} sx={{ justifyContent: "flex-end" }}>
              <Button
                variant='outlined'
                size='small'
                onClick={handleClickSelectBoard}
                sx={{
                  width: { xs: "130px", md: "170px" },
                  fontSize: { xs: "9px", md: "12px" },
                  textTransform: "capitalize",
                  margin: "5px",
                  // marginRight: { xs: "20px", md: "12px" },
                }}
              >
                {t("Change Board & Class")}
              </Button>
            </Box>
          )}
          <Box display={"flex"} alignItems={"center"} sx={{ justifyContent: "flex-end" }}>
            <>
              <Box>
                <Button
                  variant='text'
                  aria-controls='language-menu'
                  aria-haspopup='true'
                  onClick={handleLanguageMenuOpen}
                  sx={{
                    width: { xs: "60px", md: "100px" },
                    height: { xs: "24px", md: "29px" },
                    fontSize: { xs: "9px", md: "12px" },
                    textTransform: "capitalize",
                  }}
                  startIcon={
                    <LanguageIcon
                      style={{
                        fontSize: 25,
                        color: Colors.globalColorThemeColor,
                      }}
                    />
                  }
                >
                  {getLanguageLabel(currentLanguage)}
                  <ArrowDropDown />
                </Button>
                <Menu
                  id='language-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleLanguageMenuClose}
                  PaperProps={{
                    style: {
                      minWidth: 101,
                    },
                  }}
                >
                  <MenuItem sx={{ fontSize: 14 }} onClick={() => changeLanguage("en")}>
                    English
                  </MenuItem>
                  <MenuItem sx={{ fontSize: 14 }} onClick={() => changeLanguage("ta")}>
                    தமிழ்
                  </MenuItem>
                </Menu>
              </Box>
            </>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              title={
                <Box>
                  <Typography>Name: {name}</Typography>
                  <Typography>Email: {emailId}</Typography>
                  <Typography>{board === "sb" ? `Medium: ${medium}` : ""}</Typography>
                  <Typography>
                    {board === "sb" ? `Class: ${className.toString()}` : ""}

                    {/* Class: {className.substring(5)} */}
                  </Typography>
                </Box>
              }
            >
              <Avatar
                sx={{
                  m: 0.5,
                  bgcolor: Colors.globalColorThemeColor,
                  height: { xs: 40, md: 50 },
                  width: { xs: 40, md: 50 },
                  fontSize: { xs: 20, md: 23 },
                }}
              >
                {name.charAt(0).toUpperCase()}
              </Avatar>
            </Tooltip>
            {/* <Stack>
              <Typography sx={{ fontWeight: 600, fontSize: "16px" }}> {loginData.name}</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: "12px", opacity: "0.5" }}>
                {loginData.email_id}
              </Typography>
            </Stack> */}
            {/* <ArrowDropDown /> */}
          </Box>
        </Box>
      </Box>

      {isMobile && (
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 30px",
              // backgroundColor: "red",
              boxSizing: "border-box",
              gap: "15px",
            }}
          >
            <Box width={"100%"}>
              {board === "neet" && (planName !== "premium" || paymentFrequencys === "") && (
                <Button
                  variant='contained'
                  size='small'
                  onClick={onUpgradeBtnClick}
                  // className='upgrade-btn'
                  fullWidth
                  sx={{
                    // width: { xs: "100px", md: "150px" },
                    fontSize: { xs: "10px", md: "12px" },
                    textTransform: "capitalize",
                    margin: "5px",
                    backgroundColor: "#DAA520",
                    "&:hover": {
                      backgroundColor: "#DAA520",
                    },
                  }}
                >
                  {t("Upgrade Plan")}
                </Button>
              )}

              {board === "sb" && planName !== "premium" && (
                <Button
                  variant='contained'
                  size='small'
                  onClick={onUpgradeBtnClick}
                  // className='upgrade-btn'
                  fullWidth
                  sx={{
                    // width: { xs: "80px", md: "80px" },
                    fontSize: { xs: "10px", md: "12px" },
                    textTransform: "capitalize",
                    margin: "5px",
                    backgroundColor: "#DAA520",
                    "&:hover": {
                      backgroundColor: "#DAA520",
                    },
                  }}
                >
                  {t("Upgrade Plan")}
                </Button>
              )}
              {board === "cb" && planName !== "premium" && (
                <Button
                  variant='contained'
                  size='small'
                  onClick={onUpgradeBtnClick}
                  // className='upgrade-btn'
                  fullWidth
                  sx={{
                    fontSize: { xs: "10px", md: "12px" },
                    textTransform: "capitalize",
                    margin: "5px",
                    backgroundColor: "#DAA520",
                    "&:hover": {
                      backgroundColor: "#DAA520",
                    },
                  }}
                >
                  {t("Upgrade Plan")}
                </Button>
              )}
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{ justifyContent: "flex-end", width: "100%" }}
            >
              <Button
                variant='outlined'
                size='small'
                onClick={handleClickSelectBoard}
                fullWidth
                sx={{
                  fontSize: { xs: "10px", md: "12px" },
                  textTransform: "capitalize",
                  margin: "5px",
                  // marginRight: { xs: "20px", md: "12px" },
                  textWrap: "nowrap",
                }}
              >
                {t("Change Board & Class")}
              </Button>
            </Box>
          </Box>
        </div>
      )}
      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: { xs: 230, lg: drawerWidth },
            mt: 11,
            borderTopRightRadius: "15px",
          },
        }}
        variant='persistent'
        anchor='left'
        open={open}
        onClose={handleDrawerClose}
        onMouseLeave={handleDrawerClose}
      >
        <Box mt={{ xs: 0.2, sm: 0.5, md: 0, lg: 1, xl: 3 }}>
          <List>
            {AppBarData.map((obj, i) => (
              <ListItem key={obj} disablePadding sx={{ mt: { xs: 2, sm: 2, lg: 2, xl: 3 } }}>
                <ListItemButton onClick={obj.reDirect}>
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <Box
                      component={"img"}
                      src={obj.icon}
                      sx={{
                        width: { xs: "20px", lg: "22px", xl: "25px" },
                        height: { xs: "20px", lg: "22px", xl: "25px" },
                      }}
                    />
                    <Typography
                      sx={{
                        color: Colors.globalColorThemeColor,
                        fontStyle: "normal",
                        fontWeight: "600px",
                        mt: 0.2,
                        fontSize: { xs: 12, lg: 13, xl: 15 },
                      }}
                    >
                      {obj.text}
                    </Typography>
                  </Stack>
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <List sx={{ position: "absolute", bottom: { xs: 85, sm: 95 }, width: "100%" }}>
            <Stack alignItems={"center"} mb={1.5}>
              <Divider
                sx={{
                  bgcolor: Colors.globalColorThemeColor,
                  height: "0.8px",
                  opacity: "0.2",
                  width: "90%",
                  textAlign: "center",
                  borderRadius: "10px",
                }}
              />
            </Stack>

            <ListItem disablePadding>
              <ListItemButton onClick={signOutFn}>
                <Stack direction={"row"} alignItems={"center"} gap={2}>
                  <Box
                    component={"img"}
                    src={SignOutIcon}
                    sx={{
                      width: { xs: "20px", lg: "22px", xl: "25px" },
                      height: { xs: "20px", lg: "22px", xl: "25px" },
                    }}
                  />
                  <Typography
                    sx={{
                      color: Colors.globalColorThemeColor,
                      fontStyle: "normal",
                      fontWeight: "600px",
                      mt: 0.2,
                      fontSize: { xs: 12, lg: 13, xl: 15 },
                    }}
                  >
                    {t("SIGN OUT")}
                  </Typography>
                </Stack>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Termsandcondition
        open={openTermsandConditions}
        close={() => setOpenTermsandConditions(false)}
      />
    </div>
  );
};
export default SubjectNavbar;
