import { Typography, TextField, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navebar from "../../Navbar/Navebar";
import Babystudy from "./image/undraw_book_lover_mkck.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { APIClient } from "../../utils/APIClient";
import Swal from "sweetalert2";
import "animate.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./style.css";
import Colors from "../../utils/Colors";
import { changeMobileNo, CurrentMobileNo } from "../../redux/registerDataSlice";
import { useTranslation } from "react-i18next";
import Button from "../../common/components/DesignSystem/Button/Button";

const Login = () => {
  const { t } = useTranslation(["translationLogin"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mobileNo = useSelector(CurrentMobileNo);
  const [countryCode, setCountryCode] = useState("+91");
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(false);

  const handleChangeMobNo = (event) => {
    let value = event.target.value;

    if (value.length > 10) {
      value = value.slice(0, 10);
    }

    dispatch(changeMobileNo(value));
    setIsValid(false);
    setError("");

    if (/^\d{10}$/.test(value)) {
      setIsMobileValid(true);
    } else {
      setIsMobileValid(false);
    }
  };

  const sentOTP = (payload) => {
    console.log(payload);
    axios
      .post(`${APIClient().API_BASE_URL}/api/sentSmsOTP`, payload)
      .then((res) => {
        if (res.data.success) {
          navigate("/otp", {
            state: {
              isNewUser: false,
              mob_no: payload.mobNo,
              otpData: {
                otp: res.data.OTP,
                sessionId: res.data.Details,
              },
              otpV2Data: {
                otpSessionId: res.data.otpSessionId,
              },
            },
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          confirmButtonColor: Colors.globalColorThemeColor,
          title: "Server Down Try Again Later",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(() => {
          setLoading(false);
        });
      });
  };

  const onSignInBtnClick = () => {
    setLoading(true);
    if (mobileNo === "") {
      setError(t("Please Enter Mobile Number"));
      setIsValid(true);
      setLoading(false);
    } else if (mobileNo.length !== 10) {
      setError(t("Please Enter Valid Mobile Number"));
      setIsValid(true);
      setLoading(false);
    } else {
      const payload = {
        mobNo: countryCode + mobileNo.replace("-", "").replace(/\s/g, ""),
      };
      axios
        .post(`${APIClient().API_BASE_URL}/api/netSmart_number_check`, payload)
        .then((res) => {
          if (res.data.message === "No_User") {
            Swal.fire({
              icon: "error",
              confirmButtonColor: Colors.globalColorThemeColor,
              title: "This Number is Not Registered",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then(() => {
              setLoading(false);
            });
          } else {
            sentOTP(payload);
          }
        })
        .catch((err) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            confirmButtonColor: Colors.globalColorThemeColor,
            title: "Server Down Try Again Later",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          });
        });
    }
  };

  useEffect(() => {
    dispatch(changeMobileNo(""));
  }, [dispatch]);

  return (
    <div>
      <Navebar title={t("Login")} />
      <div className='holldiv'>
        <div className='belownavbarinsingin'>
          <Typography
            sx={{ fontWeight: 600, fontSize: "28px", color: Colors.globalColorThemeColor }}
          >
            {t("Welcome Back !")}
          </Typography>
          <div>
            <LazyLoadImage effect='blur' src={Babystudy} alt={"Login"} className='Babystudys' />
          </div>
        </div>
        <div>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: "16px",
              paddingRight: { xs: "168px", sm: "209px", md: "209px", lg: "209px", xl: "209px" },
              color: "grey",
              flexWrap: "nowrap",
            }}
          >
            {t("Enter Mobile Number:")}
          </Typography>
        </div>
        <div>
          <div className='entermobilenumberbox'>
            <TextField label='+91' disabled className='textsmall' />
            <div className='input-container'>
              <input
                className='textbig'
                placeholder={t("Enter Mobile Number")}
                id='mobileNo'
                inputMode='numeric'
                pattern='[0-9]{10}'
                maxLength='10'
                onChange={handleChangeMobNo}
                style={{ letterSpacing: "0.1em", paddingLeft: "15px" }}
              />
              <small className='helper-text' id='helperText'></small>
            </div>
          </div>
          <div className='footer'>
            {/* <div className='footerSec'> */}
            {/* <Button
              disabled={loading || !isMobileValid}
              variant='contained'
              onClick={onSignInBtnClick}
              className='signBtn'
              style={{ backgroundColor: Colors.globalColorThemeColor, top: "20px" }}
            >
              {loading ? t("Signing In...") : t("SIGN IN")}
            </Button> */}
            <Button
              variant='contained'
              onClick={onSignInBtnClick}
              disabled={loading || !isMobileValid}
              loading={loading}
              customStyles={{
                marginTop: "10px",
                width: {
                  xs: "340px",
                  sm: "360px",
                  md: "395px",
                  lg: "395px",
                  xl: "395px",
                },
              }}
            >
              Sign In
            </Button>
            <p>
              {t("Are You A New User?")}{" "}
              <span
                onClick={() => navigate("/selectboard")}
                style={{ color: Colors.globalColorThemeColor, cursor: "pointer" }}
              >
                {t("Register")}
              </span>{" "}
            </p>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
