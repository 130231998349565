import React, { useState, useEffect } from "react";
import { Typography, Card, Button, TextField, List, ListItem } from "@mui/material";
import Switch from "@mui/material/Switch";
import { CurrencyRupeeSharp } from "@mui/icons-material";
import { APIClient } from "../../../../utils/APIClient";
import {
  changeplanName,
  changeamount,
  changepaymentFrequency,
} from "../../../../redux/stateBoardSubscriptionDetailSlice";
import axios from "axios";
import Colors from "../../../../utils/Colors";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  changeUserID,
  CurrentName,
  CurrentEmailId,
  CurrentMobileNo,
  CurrentBoard,
  CurrentClassName,
  CurrentMedium,
} from "../../../../redux/profileDataSlice";
import "./StateBoardSubscriptionPlans.css";
import { useTranslation } from "react-i18next";

const StateBoardSubscriptionPlans = () => {
  const { t } = useTranslation(["translationStateBoardSubscriptionplans"]);
  const Contents = [
    t("Subject based Q & A"),
    t("Quiz for every subject"),
    t("Videos explanation for each concept"),
  ];
  const dispatch = useDispatch();
  const location = useLocation();
  const board = useSelector(CurrentBoard);
  const getuserID = useSelector(changeUserID);
  const name = useSelector(CurrentName);
  const emailId = useSelector(CurrentEmailId);
  const mobileNo = useSelector(CurrentMobileNo);
  const medium = useSelector(CurrentMedium);
  const className = useSelector(CurrentClassName);
  const [yearly, setYearly] = useState(true);
  const handleSwitchChange = () => {
    setYearly(!yearly);
  };
  const [pricingDetails, setPricingDetails] = useState({ month: 0, year: 0 });
  const premium = yearly
    ? pricingDetails.premiumPlanPrice?.year || 0
    : pricingDetails.premiumPlanPrice?.month || 0;
  const premiumPrice = premium.toLocaleString("en-IN");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date().toISOString());
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const handleChoosePlanButtonClick = (Price, plan, planFrequency) => {
    const newDate = new Date().toISOString(); // Get the current date in ISO format
    setCurrentDate(newDate);
    let planePrice = Price.toString();
    const gettingOrderId = {
      amount: planePrice,
    };
    axios.post(`${APIClient().API_BASE_URL}/api/razorpayapi`, gettingOrderId).then((res) => {
      setOrderId(res.data.orderId);
    });
    setSelectedAmount("10");
    const amount = parseFloat(Price.toString());
    initiatePayment(amount, orderId, plan);
  };

  const handleChoosePlan2ButtonClick = (Price) => {
    navigate("/home");
    setSelectedAmount(Price);
    const amount = parseFloat(Price.replace(/,/g, ""));
    initiatePayment(amount);
  };

  const initiatePayment = (amount, orderId, plan) => {
    const testKeys = {
      key: "rzp_test_c6qVcdC2EOngSN",
      key_secret: "9DBOHByv5alLCVMnKgflEh7x",
    };
    // const liveKeys = {
    //   key: "rzp_live_y3TYjEL32Vj556",
    //   key_secret: "xJIERDCsOpMOmV4W8MxT9YOA",
    // };
    if (amount === "") {
      alert("Please select an amount");
    } else {
      var options = {
        ...testKeys,
        amount: amount * 100,
        currency: "INR",
        name: "Gillgall Pvt. Ltd",
        description: "For testing purpose",
        orderId: orderId,
        image: "https://i.postimg.cc/5yLgwnDt/logo-Copy.png",
        handler: function (response) {
          let payload = {
            userId: getuserID.payload.profileData.userID,
            transactionId: response.razorpay_payment_id,
            amount: amount,
            paymentDate: currentDate,
            planName: plan,
            paymentFrequency: yearly ? "year" : "month",
          };
          const currentdate = new Date();
          axios.put(`${APIClient().API_BASE_URL}/api/updateSbSubscription`, payload).then((res) => {
            const updateSubscriptionList =
              res.data.user.stateBoardSubscription[0].updateSubscriptions;
            const filterItems = updateSubscriptionList.filter(
              (item) => item.class_name === className && item.medium === medium,
            )[0];
            const endDate = new Date(filterItems.endDate);
            if (endDate > currentdate) {
              navigate("/home");
            }
          });
        },
        prefill: {
          name: name,
          email: emailId,
          contact: mobileNo,
        },
        notes: {
          address: "Gillgall Pvt. Ltd",
        },
        theme: {
          color: Colors.globalColorThemeColor,
        },
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  };
  const onUpgradeBtnClick = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${APIClient().API_BASE_URL}/api/getPlanPricing`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const data = response.data.message;
        const premiumPlan = data.find((plan) => plan.planName === "premium");
        const premiumPlanPrice = premiumPlan.pricingDetails;
        var pricingDetails;
        pricingDetails = { premiumPlanPrice };
        if (board === "sb") {
          setPricingDetails(pricingDetails);
        }
      })
      .catch((error) => {
        console.error("Error fetching plan pricing:", error); // Error handling
      });
  };

  useEffect(() => {
    onUpgradeBtnClick();
  }, []);
  return (
    <>
      <div style={{ height: "100vh" }}>
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            height: "60vh",
            overflow: "hidden",
            marginTop: "0px",
          }}
        >
          <img
            style={{ width: "110%", marginTop: "-10px" }}
            src={require("../assets/cover.png")}
            alt='Cover'
          />
          <img
            style={{
              width: "1135px",
              height: "738px",
              position: "absolute",
              marginRight: "860px",
            }}
            src={require("../assets/insideCover.png")}
            alt='Insidecover'
          />

          <img
            src={require("../assets/insideCover2.png")}
            alt='Inside Cover'
            height='120px'
            style={{
              marginLeft: "1300px",
              marginTop: "140px",
              position: "absolute",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            className='sb-headings'
            // style={{
            //   position: "absolute",
            //   color: "#FFFFFF",
            //   font: "public-sans",
            //   fontSize: "35px",
            //   fontWeight: 700,
            //   "@media (max-width: 300px)and (max-width:500px)": {
            //     fontSize: "2px",
            //     fontWeight: 600,
            //   },
            // }}
          >
            {t("STATE BOARD Subscription Plans")}
            {/* STATE BOARD Subscription Plans */}
          </Typography>
          <Typography
            className='sb-months'
            style={{
              color: "#FFFFFF",
              font: "public-sans",
              fontSize: "15px",
              fontWeight: 600,
              position: "absolute",
              marginTop: "286px",
              marginRight: "150px",
            }}
          >
            {t("Monthly")}
          </Typography>
          <Switch
            className='sb-icons'
            defaultChecked
            // sx={{ transform: "scale(1.5)", marginTop: "280px" }}
            onChange={handleSwitchChange}
          />
          <Typography
            className='sb-years'
            style={{
              color: "#21A6FD",
              font: "public-sans",
              fontSize: "15px",
              fontWeight: 600,
              position: "absolute",
              marginTop: "286px",
              marginLeft: t("Yearly") === "வருடாந்திர" ? "160px" : "140px",
            }}
          >
            {t("Yearly")}
          </Typography>
        </div>
        <div className='sb-subscriptions'>
          <Card
            elevation={8}
            sx={{
              // height: "520px",
              // width: "369px",
              marginBottom: "20px",
              background: "#ffffff",
              borderStartEndRadius: "50px",
              borderBottomLeftRadius: "50px",
              border: "1px solid #999999",

              width: { xs: "300px", sm: "369px", md: "369px", lg: "369px", xl: "369px" },
              height: { xs: "475px", sm: "520px", md: "520px", lg: "520px", xl: "520px" },
            }}
            style={
              {
                // marginBottom: "20px",
                // "@media only screen and (min-width: 580px) and (max-width: 900px)": {
                //   width: "30%",
                // },
              }
            }
          >
            <Typography
              sx={{
                font: "hind",
                //fontSize: "25px",
                fontWeight: 600,
                marginTop: "70px",
                marginLeft: "40px",
                fontSize: {
                  xs: "17px",
                  sm: "18px",
                  lg: "20px",
                  md: "25px",
                  xl: "25px",
                },
              }}
            >
              {t("Basic")}
            </Typography>
            <List
              sx={{
                paddingLeft: "20px",
                marginTop: "70px",
                //marginLeft: "20px",
                // "@media (min-width: 350px) and (max-width: 480px)": {
                //   marginTop: "30px",
                // },
              }}
            >
              {Contents.map((content, index) => (
                <ListItem
                  key={index}
                  sx={{
                    listStyle: "none",
                    //marginBottom: "25px",
                    fontSize: "15px",
                    display: "flex",
                    // textWrap: "nowrap",
                    alignItems: "center",
                    "@media (min-width: 350px) and (max-width: 480px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  {content === "Pre-Upload Classes" || content === "Mock exams" ? (
                    <>
                      <img
                        style={{ height: "30px" }}
                        src={require("../assets/available.png")}
                        alt={`${Contents} available`}
                      />
                      <span style={{ marginRight: "10px" }}>{content}</span>
                      <span style={{ fontSize: "14px", color: "#999" }}>(limited access)</span>
                    </>
                  ) : (
                    <>
                      <img
                        style={{ height: "30px" }}
                        src={require("../assets/notAvailable.png")}
                        alt={`${content} notAvailable`}
                      />
                      <span style={{ marginRight: "10px", color: "grey" }}>{content}</span>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
            <Button
              variant='contained'
              sx={{
                borderEndStartRadius: "17px",
                marginLeft: "90px",
                marginTop: "30px",
                font: "hind",
                // fontSize: "16px",
                fontWeight: 600,
                backgroundColor: "#21A6FD",
                textTransform: "none",
                "&:hover": { backgroundColor: "#21A6FD" },
                width: { xs: "125px", sm: "200px", md: "200px", lg: "200px", xl: "200px" },
                height: { xs: "45px", sm: "50px", md: "50px", lg: "50px", xl: "50px" },
                fontSize: { xs: "10px", sm: "16px", md: "16px", lg: "16px", xl: "16px" },
              }}
              // onClick={() => handlePremiumPlan("basic")}
              onClick={() => handleChoosePlan2ButtonClick(0, "basic", yearly)}
            >
              {t("Free")}
            </Button>
          </Card>
          <Card
            elevation={8}
            sx={{
              background: "#ffffff",
              borderStartEndRadius: "50px",
              borderBottomLeftRadius: "50px",
              border: "1px solid #999999",

              width: { xs: "300px", sm: "369px", md: "369px", lg: "369px", xl: "369px" },
              height: { xs: "475px", sm: "520px", md: "520px", lg: "520px", xl: "520px" },
            }}
            style={{
              marginBottom: "20px",
              "@media only screen and (min-width: 580px) and (max-width: 900px)": {
                width: "30%",
              },
            }}
          >
            <Typography
              sx={{
                font: "hind",
                //fontSize: "25px",
                fontWeight: 600,
                marginTop: "70px",
                marginLeft: "40px",
                fontSize: {
                  xs: "17px",
                  sm: "18px",
                  lg: "20px",
                  md: "25px",
                  xl: "25px",
                },
              }}
            >
              {t("Premium")}
            </Typography>
            <Typography
              sx={{
                font: "hind",
                fontSize: "35px",
                color: "#000000",
                fontWeight: 900,
                display: "flex",
                alignItems: "center",
                marginLeft: "30px",
              }}
            >
              <CurrencyRupeeSharp
                sx={{
                  width: "40px",
                  height: "40px",
                  "@media (min-width: 350px) and (max-width: 480px)": {
                    fontSize: "13px",
                    width: "30px",
                    height: "30px",
                  },
                }}
              />
              {premiumPrice}
              {yearly ? (
                <>
                  <Typography
                    sx={{
                      fontSize: "25px",
                      display: "inline",
                      "@media (min-width: 350px) and (max-width: 480px)": {
                        fontSize: "18px",
                      },
                    }}
                  >
                    {"yr"}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#21A6FD",
                      marginLeft: "8px",
                      fontSize: "26px",
                      fontWeight: 600,
                      display: "inline",
                      "@media (min-width: 350px) and (max-width: 480px)": {
                        fontSize: "13px",
                      },
                    }}
                  >
                    + GST
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: "25px",
                      display: "inline",
                      "@media (min-width: 350px) and (max-width: 480px)": {
                        fontSize: "18px",
                      },
                    }}
                  >
                    {"mo"}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#21A6FD",
                      marginLeft: "8px",
                      fontSize: "26px",
                      fontWeight: 600,
                      display: "inline",
                      "@media (min-width: 350px) and (max-width: 480px)": {
                        fontSize: "13px",
                      },
                    }}
                  >
                    + GST
                  </Typography>
                </>
              )}
            </Typography>
            <List
              sx={{
                paddingLeft: "20px",
                marginTop: "20px",
                // "@media (min-width: 350px) and (max-width: 480px)": {
                //   marginTop: "-10px",
                // },
              }}
            >
              {Contents.map((content, index) => (
                <ListItem
                  key={index}
                  sx={{
                    listStyle: "none",
                    //marginBottom: "25px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                    "@media (min-width: 350px) and (max-width: 480px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  <img
                    style={{ height: "30px" }}
                    src={require("../assets/available.png")}
                    alt={`${content} available`}
                  />

                  <span style={{ marginRight: "10px" }}>{content}</span>
                </ListItem>
              ))}
            </List>
            <Button
              onClick={() => handleChoosePlanButtonClick(premium, "premium", yearly)}
              variant='contained'
              sx={{
                borderEndStartRadius: "17px",
                marginLeft: "90px",
                marginTop: "30px",
                font: "hind",
                // fontSize: "15px",
                fontWeight: 600,
                backgroundColor: "#21A6FD",
                textTransform: "none",
                "&:hover": { backgroundColor: "#21A6FD" },
                width: { xs: "125px", sm: "200px", md: "200px", lg: "200px", xl: "200px" },
                height: { xs: "45px", sm: "50px", md: "50px", lg: "50px", xl: "50px" },
                fontSize: { xs: "9px", sm: "15px", md: "15px", lg: "15px", xl: "15px" },
              }}
            >
              {t("Choose Plan")}
            </Button>
          </Card>
        </div>
      </div>
    </>
  );
};
export default StateBoardSubscriptionPlans;
